import React, { useState } from "react";
import {
  Card,
  TextInput,
  Label,
  Button,
  Textarea,
  Modal,
  Alert,
  FileInput,
} from "flowbite-react";
import MainLayout from "../../layouts/MainLayout";
import Chatbot from "./components/chatbot";
import { getChatbotPreview } from "../../api/chat";
import { addDomain as addDomainApi } from "../../api/domain";
import { ChatbotData, ChatbotPreviewResponse } from "../../utils/types/chat";
import { DomainData } from "../../utils/types/domains";
import { useAuth } from "../../utils/helpers/authWrapper";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { FaGlobe, FaRobot, FaPalette, FaImage, FaQuoteLeft, FaUserTie } from "react-icons/fa";

const AddDomain: React.FC = () => {
  const [color, setColor] = useState("#000000");
  const [link, setLink] = useState("");
  const [country, setCountry] = useState("");
  const [company, setCompany] = useState("");
  const [chatBotName, setChatBotName] = useState("");
  const [chatBotTagline, setChatBotTagline] = useState("");
  const [chatBotRole, setChatBotRole] = useState("");
  const [chatBotLogo, setChatBotLogo] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [chatBotLogoName, setChatBotLogoName] = useState("");
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setColor(e.target.value);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setChatBotLogoName(file.name);
      const reader = new FileReader();
      reader.onloadend = () => {
        setChatBotLogo(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (chatBotLogo) {
      const domainData: DomainData = {
        chat_bot_color: color,
        chat_bot_logo: chatBotLogo,
        chat_bot_name: chatBotName,
        chat_bot_extra: chatBotRole,
        chat_bot_tagline: chatBotTagline,
        chat_bot_logo_name: chatBotLogoName,
        company: company,
        country: country,
        user_id: user?.id ?? 0,
        website: link,
      };

      try {
        const response = await addDomainApi(domainData);
        if (response === "Success") {
          setModalMessage("Domain added successfully!");
          setShowModal(true);
        } else {
          setModalMessage("An error occurred while saving the domain.");
          setShowModal(true);
        }
      } catch (error) {
        setModalMessage("An error occurred while saving the domain.");
        setShowModal(true);
        console.error("Error saving domain:", error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const closeModal = () => {
    setShowModal(false);
    if (modalMessage === "Domain added successfully!") {
      navigate("/domains");
    }
  };

  const getPreview = async (
    data: ChatbotData
  ): Promise<ChatbotPreviewResponse> => {
    return await getChatbotPreview(data);
  };

  return (
    <MainLayout>
      <motion.div
        className="px-4 pt-6 h-full"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 h-full">
          <div className="lg:col-span-2 h-full flex-grow">
            <Card className="p-6 h-full flex flex-col">
              <h2 className="text-2xl font-bold mb-6 text-gray-800 dark:text-white">
                Add New Domain
              </h2>
              <form className="space-y-6 flex-grow" onSubmit={handleSubmit}>
                <div>
                  <div className="flex items-center mb-2">
                    <FaGlobe className="mr-2 text-gray-500" />
                    <Label htmlFor="website" value="Website Link" />
                  </div>
                  <TextInput
                    id="website"
                    type="url"
                    placeholder="https://example.com"
                    value={link}
                    onChange={(e) => setLink(e.target.value)}
                    pattern="https?://.+"
                    required
                  />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <div className="flex items-center mb-2">
                      <FaGlobe className="mr-2 text-gray-500" />
                      <Label htmlFor="country" value="Country" />
                    </div>
                    <TextInput
                      id="country"
                      type="text"
                      placeholder="Enter country"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      required
                    />
                  </div>
                  <div>
                    <div className="flex items-center mb-2">
                      <FaUserTie className="mr-2 text-gray-500" />
                      <Label htmlFor="company" value="Company" />
                    </div>
                    <TextInput
                      id="company"
                      type="text"
                      placeholder="Enter company name"
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div>
                  <div className="flex items-center mb-2">
                    <FaRobot className="mr-2 text-gray-500" />
                    <Label htmlFor="chatBotName" value="Chat Bot Name" />
                  </div>
                  <TextInput
                    id="chatBotName"
                    type="text"
                    placeholder="Enter chat bot name"
                    value={chatBotName}
                    onChange={(e) => setChatBotName(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <div className="flex items-center mb-2">
                    <FaPalette className="mr-2 text-gray-500" />
                    <Label htmlFor="chatBotTheme" value="Chat Bot Theme" />
                  </div>
                  <div className="flex items-center space-x-2">
                    <input
                      id="chatBotTheme"
                      type="color"
                      value={color}
                      onChange={handleColorChange}
                      className="h-10 w-10 border rounded-md cursor-pointer"
                    />
                    <TextInput
                      id="chatBotThemeCode"
                      type="text"
                      value={color}
                      readOnly
                      className="w-full"
                    />
                  </div>
                </div>
                <div>
                  <div className="flex items-center mb-2">
                    <FaImage className="mr-2 text-gray-500" />
                    <Label htmlFor="chatBotLogoUrl" value="Chat Bot Logo" />
                  </div>
                  <FileInput
                    id="chatBotLogoUrl"
                    accept=".jpeg,.jpg,.webp,.gif,.bmp,.tiff,.svg"
                    onChange={handleFileChange}
                    required
                  />
                </div>
                <div>
                  <div className="flex items-center mb-2">
                    <FaQuoteLeft className="mr-2 text-gray-500" />
                    <Label htmlFor="chatBotTagline" value="Chat Bot Tagline" />
                  </div>
                  <TextInput
                    id="chatBotTagline"
                    type="text"
                    placeholder="Enter chat bot tagline"
                    value={chatBotTagline}
                    onChange={(e) => setChatBotTagline(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <div className="flex items-center mb-2">
                    <FaRobot className="mr-2 text-gray-500" />
                    <Label htmlFor="chatBotRole" value="Chat Bot Role" />
                  </div>
                  <Textarea
                    id="chatBotRole"
                    placeholder="Enter chat bot training data"
                    rows={4}
                    value={chatBotRole}
                    onChange={(e) => setChatBotRole(e.target.value)}
                  />
                </div>
                <Button type="submit" gradientDuoTone="purpleToBlue" disabled={isSubmitting}>
                  {isSubmitting ? "Submitting..." : "Add Domain"}
                </Button>
              </form>
            </Card>
          </div>
          <div className="lg:col-span-1 h-full flex-grow">
            <Card className="p-6 h-full flex flex-col">
              <h2 className="text-2xl font-bold mb-6 text-gray-800 dark:text-white">
                Chatbot Preview
              </h2>
              <Chatbot
                link={link}
                country={country}
                company={company}
                chatBotName={chatBotName}
                chatBotTheme={color}
                chatBotLogo={chatBotLogo}
                chatBotTagline={chatBotTagline}
                chatBotRole={chatBotRole}
                getPreview={getPreview}
              />
            </Card>
          </div>
        </div>
      </motion.div>
      <Modal show={showModal} onClose={closeModal}>
        <Modal.Header>
          {modalMessage === "Domain added successfully!" ? "Success" : "Error"}
        </Modal.Header>
        <Modal.Body>
          <Alert
            color={modalMessage === "Domain added successfully!" ? "success" : "failure"}
            className="mb-4"
          >
            <p className="text-base font-medium">
              {modalMessage}
            </p>
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button gradientDuoTone="purpleToBlue" onClick={closeModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    </MainLayout>
  );
};

export default AddDomain;
