import React from "react";

interface ChatMessageProps {
  message: string;
  sender: "user" | "assistant" | "system" | "human_assistant";
  initials: string;
}

const ChatMessage: React.FC<ChatMessageProps> = ({ message, sender, initials }) => {
  const isUser = sender === "user";
  const isAssistant = sender === "assistant";
  const isHumanAssistant = sender === "human_assistant";
  const isSystem = sender === "system";

  const getJustifyClass = (sender: ChatMessageProps["sender"]) => {
    if (sender === "user") return "justify-start";
    if (sender === "assistant" || sender === "human_assistant") return "justify-end";
    return "justify-center";
  };

  const filterMessage = (message: string): string => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(message, 'text/html');
    const btnContainers = doc.querySelectorAll('.rupeni-option-btn-container');
    btnContainers.forEach(container => container.remove());
    return doc.body.innerHTML;
  };

  const filteredMessage = filterMessage(message);

  const renderMessageContent = () => (
    <div
      className="text-sm text-gray-800 dark:text-gray-200"
      dangerouslySetInnerHTML={{ __html: isUser ? filteredMessage.replace(/\n/g, '<br />') : filteredMessage }}
    />
  );

  return (
    <div className={`flex ${getJustifyClass(sender)} mb-4`}>
      {isUser && (
        <div className="flex items-end space-x-2 mr-20">
          <div className="w-8 h-8 rounded-full flex items-center justify-center bg-blue-100 text-blue-800 dark:bg-blue-800 dark:text-blue-100">
            {initials}
          </div>
          <div className="border p-2 rounded-lg shadow-md bg-white dark:bg-gray-700 border-gray-200 dark:border-gray-600">
            {renderMessageContent()}
          </div>
        </div>
      )}
      {isAssistant && (
        <div className="flex items-end space-x-2 ml-20">
          <div className="bg-green-100 dark:bg-green-800 border border-green-200 dark:border-green-700 p-2 rounded-lg shadow-md">
            {renderMessageContent()}
          </div>
        </div>
      )}
      {isHumanAssistant && (
        <div className="flex items-end space-x-2 ml-20">
          <div className="bg-blue-100 dark:bg-blue-900 border border-blue-200 dark:border-blue-700 p-2 rounded-lg shadow-md">
            {renderMessageContent()}
          </div>
        </div>
      )}
      {isSystem && (
        <div className="text-center text-gray-500 dark:text-gray-400 text-xs">{filteredMessage}</div>
      )}
    </div>
  );
};

export default ChatMessage;
