import React, { useState } from "react";
import { Card, ListGroup, Tooltip } from "flowbite-react";
import {
  HiCheck,
  HiExclamationCircle,
  HiRefresh,
  HiSupport,
  HiClipboardCopy,
} from "react-icons/hi";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { motion } from "framer-motion";
import MainLayout from "../../layouts/MainLayout";

const Installation: React.FC = () => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const fadeInUp = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.5 }
  };

  return (
    <MainLayout>
      <motion.div 
        className="max-w mx-auto px-4 py-8"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <Card className="p-6 shadow-lg dark:bg-gray-800">
          <motion.h1 
            className="text-3xl font-bold mb-8 text-purple-600 dark:text-purple-400"
            {...fadeInUp}
          >
            Rupeni AI Integration Guide
          </motion.h1>

          <motion.section className="mb-10" {...fadeInUp}>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800 dark:text-gray-200">
              Prerequisites
            </h2>
            <ListGroup className="space-y-2">
              <ListGroup.Item className="bg-white dark:bg-gray-700">
                <HiCheck className="inline-block mr-2 text-green-500" /> Basic knowledge of HTML and JavaScript
              </ListGroup.Item>
              <ListGroup.Item className="bg-white dark:bg-gray-700">
                <HiCheck className="inline-block mr-2 text-green-500" /> A website where you can add custom scripts
              </ListGroup.Item>
            </ListGroup>
          </motion.section>

          <motion.section className="mb-10" {...fadeInUp}>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800 dark:text-gray-200">
              Installation Instructions
            </h2>
            <p className="mb-4 text-gray-600 dark:text-gray-300">
              Add the following script to your site, placing it as the last element in your <code className="bg-gray-100 dark:bg-gray-600 px-1 py-0.5 rounded">&#60;head&#62;</code> tag:
            </p>
            <div className="relative">
              <pre className="bg-gray-100 p-4 rounded-lg text-sm overflow-x-auto dark:bg-gray-700">
                <code className="text-purple-600 dark:text-purple-400">
                  &#60;script src="https://api.rupeni.com/cdn/rupeni-min.js"&#62;&#60;/script&#62;
                </code>
              </pre>
              <CopyToClipboard
                text='<script src="https://api.rupeni.com/cdn/rupeni-min.js"></script>'
                onCopy={handleCopy}
              >
                <button
                  aria-label="Copy to clipboard"
                  className="absolute top-2 right-2 bg-purple-100 rounded-full p-2 hover:bg-purple-200 transition-colors duration-200 dark:bg-purple-900 dark:hover:bg-purple-800"
                >
                  <Tooltip content={copied ? "Copied!" : "Copy to clipboard"}>
                    <HiClipboardCopy className="text-purple-600 dark:text-purple-400" />
                  </Tooltip>
                </button>
              </CopyToClipboard>
            </div>
          </motion.section>

          <motion.section className="mb-10" {...fadeInUp}>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800 dark:text-gray-200">
              Setup Instructions
            </h2>
            <p className="text-gray-600 dark:text-gray-300">
              After adding the script tag, the chatbot will automatically initialize itself. No additional configuration is required.
            </p>
          </motion.section>

          <motion.section className="mb-10" {...fadeInUp}>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800 dark:text-gray-200">
              Customization Options
            </h2>
            <p className="text-gray-600 dark:text-gray-300 mb-2">
              Customize the chatbot's appearance and behavior through the dashboard settings.
            </p>
            <p className="text-gray-600 dark:text-gray-300">
              For a full list of customization options, refer to our{" "}
              <a href="#" className="text-purple-600 hover:text-purple-700 underline dark:text-purple-400 dark:hover:text-purple-300">
                documentation
              </a>.
            </p>
          </motion.section>

          <motion.section className="mb-10" {...fadeInUp}>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800 dark:text-gray-200">
              Troubleshooting
            </h2>
            <ListGroup className="space-y-2">
              <ListGroup.Item className="bg-white dark:bg-gray-700">
                <HiExclamationCircle className="inline-block mr-2 text-yellow-500" /> Verify the script URL is correct and accessible if the chatbot doesn't appear.
              </ListGroup.Item>
              <ListGroup.Item className="bg-white dark:bg-gray-700">
                <HiExclamationCircle className="inline-block mr-2 text-yellow-500" /> Check the browser console for errors and address them accordingly.
              </ListGroup.Item>
              <ListGroup.Item className="bg-white dark:bg-gray-700">
                <HiRefresh className="inline-block mr-2 text-blue-500" /> Clear your browser cache and refresh the page to apply the latest updates.
              </ListGroup.Item>
              <ListGroup.Item className="bg-white dark:bg-gray-700">
                <HiSupport className="inline-block mr-2 text-purple-500" /> For further assistance, visit our{" "}
                <a href="#" className="text-purple-600 hover:text-purple-700 underline dark:text-purple-400 dark:hover:text-purple-300">
                  support page
                </a>.
              </ListGroup.Item>
            </ListGroup>
          </motion.section>
        </Card>
      </motion.div>
    </MainLayout>
  );
};

export default Installation;
