import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { ReactTyped } from "react-typed";
import "./styles.css";
import { rupeni } from "../../assets/images";
import LoginComponent from "../../components/LoginComponent";
import ForgotPasswordComponent from "../../components/ForgotPW";
import { useAuth } from "../../utils/helpers/authWrapper";
import RegisterComponent from "../../components/RegistrationComponent";

const Login: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [tab, setTab] = useState("login");
  const { authenticated } = useAuth();

  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    const tabParam = Array.isArray(queryParams.tab)
      ? queryParams.tab[0]
      : queryParams.tab;
    setTab(tabParam ?? "login");
  }, [location.search]);

  useEffect(() => {
    if (authenticated) {
      navigate("/dashboard");
    }
  }, [authenticated, navigate]);

  const handleTabChange = (newTab: string) => {
    navigate({
      pathname: location.pathname,
      search: `?tab=${newTab}`,
    });
    setTab(newTab);
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-gray-100 to-gray-300 grid lg:grid-cols-4 relative overflow-hidden">
      <div className="hidden lg:flex lg:col-span-1 bg-gradient-to-r from-[#0D151F] to-[#1E212A] text-white  flex-col items-center relative z-10 animate-fade-in-banner">
        <img src={rupeni} alt="Logo" className="mt-8 w-44 h-44 cursor-pointer" onClick={() => navigate("/")} />
        <div className="flex flex-col items-center justify-start mt-48 flex-grow p-8 animate-slide-in-banner">
          <h1 className="text-4xl font-bold mb-4 text-primary">
            <ReactTyped
              strings={["Welcome to Rupeni", "AI Made easy", "Discover the potential of AI with Rupeni"]}
              typeSpeed={100}
              backSpeed={50}
              loop
            />
          </h1>
          <p className="text-lg">
            You are one step away from accessing Rupeni. Login or
            Register to get started.
          </p>
        </div>
      </div>

      {tab === "login" ? (
        <LoginComponent setTab={handleTabChange} />
      ) : tab === "registration" ? (
        <RegisterComponent setTab={handleTabChange} />
      ) : tab === "forgot-password" ? (
        <ForgotPasswordComponent setTab={handleTabChange} />
      ) : null}
    </div>
  );
};

export default Login;
