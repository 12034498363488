import React, { useState, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MainLayout from "../../layouts/MainLayout";
import { DomainData } from "../../utils/types/domains";
import Papa from "papaparse";
import pdfToText from "react-pdftotext";
import { updateDomain } from "../../api/domain";
import { Button, Card, Label, Textarea, FileInput, Modal, Alert } from "flowbite-react";
import {  FaSave, FaRobot } from "react-icons/fa";
import { motion } from "framer-motion";

const Train: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state as { domain: DomainData };
  const domain = state?.domain;

  const [trainingData, setTrainingData] = useState(domain?.chat_bot_extra || "");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [fileUploadStatus, setFileUploadStatus] = useState<string | null>(null);

  const handleTrainingDataChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTrainingData(event.target.value);
  };

  const handleFileChange = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      setIsLoading(true);
      setFileUploadStatus("Uploading files...");
      let allText = trainingData;
      for (const file of Array.from(files)) {
        try {
          if (file.type === "application/pdf") {
            allText += await readPdfFile(file);
          } else if (file.type === "text/csv" || file.type === "application/vnd.ms-excel") {
            allText += await readCsvFile(file);
          } else if (file.type === "text/plain") {
            allText += await readTextFile(file);
          } else {
            console.warn("Unsupported file type:", file.type);
          }
        } catch (error) {
          console.error("Error reading file:", error);
          setFileUploadStatus(`Error reading file: ${file.name}`);
        }
      }
      setTrainingData(allText);
      setIsLoading(false);
      setFileUploadStatus("Files uploaded successfully!");
    }
  }, [trainingData]);

  const readTextFile = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve(e.target?.result as string);
      };
      reader.onerror = (e) => {
        reject(new Error(e.target?.result as string));
      };
      reader.readAsText(file);
    });
  };

  const readCsvFile = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      Papa.parse<string[]>(file, {
        complete: (result) => {
          const csvText = result.data
            .map((row: string[]) => row.join(","))
            .join("\n");
          resolve(csvText);
        },
        error: (error) => {
          reject(new Error("Error reading CSV file: " + error.message));
        },
      });
    });
  };

  const readPdfFile = async (file: File): Promise<string> => {
    try {
      const text = await pdfToText(file);
      return text;
    } catch (error) {
      console.error("Failed to extract text from pdf", error);
      return "";
    }
  };

  const handleSave = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    const domainData = {
      chat_bot_color: domain.chat_bot_color,
      chat_bot_logo: domain.chat_bot_logo,
      chat_bot_logo_name: domain.chat_bot_logo_name,
      chat_bot_name: domain.chat_bot_name,
      chat_bot_extra: trainingData,
      chat_bot_tagline: domain.chat_bot_tagline,
      company: domain.company,
      country: domain.country,
      domain: domain.domain,
    };

    try {
      const response = await updateDomain(domainData);
      if (response) {
        setModalMessage("Domain updated successfully!");
        setIsModalOpen(true);
      } else {
        setModalMessage("An error occurred while updating the domain.");
        setIsModalOpen(true);
      }
    } catch (error) {
      setModalMessage("An error occurred while updating the domain.");
      setIsModalOpen(true);
      console.error("Error updating domain:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    navigate("/domains");
  };

  return (
    <MainLayout>
      <motion.div
        className=" mx-auto px-4 py-6"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        {domain ? (
          <Card className="p-6">
            <h1 className="text-3xl font-bold mb-6 text-gray-900 dark:text-white flex items-center">
              <FaRobot className="mr-2" /> Train {domain.chat_bot_name}
            </h1>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
              <div>
                <p className="text-gray-700 dark:text-gray-300">
                  <strong>Domain:</strong> {domain.domain}
                </p>
                <p className="text-gray-700 dark:text-gray-300">
                  <strong>Website:</strong> {domain.website}
                </p>
              </div>
              <div>
                <p className="text-gray-700 dark:text-gray-300">
                  <strong>Country:</strong> {domain.country}
                </p>
                <p className="text-gray-700 dark:text-gray-300">
                  <strong>Company:</strong> {domain.company}
                </p>
              </div>
            </div>

            <form onSubmit={handleSave} className="space-y-6">
              <div>
                <Label htmlFor="trainingData" value="Enter Training Data for the Chatbot:" />
                <Textarea
                  id="trainingData"
                  name="trainingData"
                  rows={15}
                  placeholder="Enter the training data here..."
                  value={trainingData}
                  onChange={handleTrainingDataChange}
                  className="mt-2"
                />
              </div>
              <div>
                <Label htmlFor="fileUpload" value="Upload Files (PDF, CSV, TXT):" />
                <FileInput
                  id="fileUpload"
                  accept=".pdf,.csv,.txt"
                  multiple
                  onChange={handleFileChange}
                  className="mt-2"
                />
                {fileUploadStatus && (
                  <Alert color={fileUploadStatus.includes("Error") ? "failure" : "success"} className="mt-2">
                    {fileUploadStatus}
                  </Alert>
                )}
              </div>

              <Button type="submit" gradientDuoTone="purpleToBlue" disabled={isLoading}>
                <FaSave className="mr-2" /> {isLoading ? "Saving..." : "Save Training Data"}
              </Button>
            </form>
          </Card>
        ) : (
          <Alert color="failure">No domain data available.</Alert>
        )}

        <Modal show={isModalOpen} onClose={closeModal}>
          <Modal.Header>Update Status</Modal.Header>
          <Modal.Body>
            <p className="text-gray-700 dark:text-gray-300">{modalMessage}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={closeModal}>Close</Button>
          </Modal.Footer>
        </Modal>
      </motion.div>
    </MainLayout>
  );
};

export default Train;
