import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useWebSocketContext } from '../../utils/context';
import callRingtone from '../../assets/sound/notification-sound.wav';

interface CallNotification {
  id: string;
  type: 'new_call_request';
  message: string;
  sessionId: string;
  domain: string;
  caller?: string;
}

export const CallAlert: React.FC = () => {
  const { notifications, clearNotification, sendMessage } = useWebSocketContext();
  const location = useLocation();
  const navigate = useNavigate();
  const [currentCall, setCurrentCall] = useState<CallNotification | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const intervalRef = useRef<number | null>(null);

  // Move this inside useEffect to prevent recalculation on every render
  useEffect(() => {
    // Filter and transform notifications
    const callNotifications = notifications
      .filter(n => n.type === 'new_call_request')
      .map(n => ({
        ...n,
        type: 'new_call_request' as const,
        caller: n.caller ?? 'Unknown Caller'
      }));

    // Update current call if there's a new one
    const latestCall = callNotifications[0] || null;
    if (JSON.stringify(latestCall) !== JSON.stringify(currentCall)) {
      setCurrentCall(latestCall);
    }
  }, [notifications]); // Only depend on notifications

  // Audio setup
  useEffect(() => {
    audioRef.current = new Audio(callRingtone);
    audioRef.current.loop = true;

    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []); // Empty dependency array as this should only run once

  // Handle audio playing
  useEffect(() => {
    if (currentCall && audioRef.current) {
      audioRef.current.play().catch(error => console.error("Error playing audio:", error));
    } else {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    }
  }, [currentCall]); // Only depend on currentCall

  if (!currentCall) return null;

  const handleAccept = () => {
    if (currentCall) {
      // Stop the ringtone
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }

      // Send accept message through WebSocket
      sendMessage(JSON.stringify({
        type: "accept_call",
        session_id: currentCall.sessionId
      }), 'support');

      // Navigate to chats with call information
      navigate('/chats', { 
        state: { 
          sessionId: currentCall.sessionId,
          isCall: true,
          caller: currentCall.caller,
          domain: currentCall.domain
        } 
      });

      // Clear the notification
      clearNotification(currentCall.id);
    }
  };

  const handleDecline = () => {
    if (currentCall) {
      // Stop the ringtone
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }

      // Send decline message through WebSocket
      sendMessage(JSON.stringify({
        type: "decline_call",
        session_id: currentCall.sessionId
      }), 'support');

      // Clear the notification
      clearNotification(currentCall.id);
    }
  };

  // Don't show call alert if we're on the chats page
  if (location.pathname === '/chats') {
    return null;
  }

  return (
    <div className="fixed bottom-4 right-4 bg-gray-800 p-4 rounded-lg shadow-lg max-w-sm">
      <p className="text-white font-semibold text-center mb-8">Incoming Call...</p>
      
      {/* Avatar and Info Box */}
      <div className="relative mt-4 mb-4">
        {/* Avatar - positioned to overlap the box */}
        <div className="absolute -top-8 left-1/2 transform -translate-x-1/2">
          <div className="w-16 h-16 bg-blue-500 rounded-full flex items-center justify-center border-4 border-gray-800">
            <svg className="w-8 h-8 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
            </svg>
          </div>
        </div>

        {/* Info Box */}
        <div className="bg-gray-700 rounded-lg p-4 pt-10 mt-4 text-center">
          <p className="text-white font-medium mt-2">{currentCall.caller ?? 'Unknown Caller'}</p>
        </div>
      </div>

      {/* Buttons Container */}
      <div className="flex flex-col gap-2">
        {/* Accept and Decline buttons row */}
        <div className="flex justify-between gap-2">
          <button
            onClick={handleAccept}
            className="flex-1 px-6 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition-colors flex items-center justify-center"
          >
            <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
            </svg>
            Accept
          </button>
          <button
            onClick={handleDecline}
            className="flex-1 px-6 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors flex items-center justify-center"
          >
            <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
            Decline
          </button>
        </div>
        
        {/* Put on Hold button - full width */}
        <button
          onClick={() => {/* Add hold functionality */}}
          className="w-full px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors flex items-center justify-center"
        >
          <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 9v6m4-6v6m-7-3h10" />
          </svg>
          Put on Hold
        </button>
      </div>
    </div>
  );
};
