import axios from './axiosConfig';
import { isAxiosError } from 'axios';
import { CompanyDetails, UserPreferences } from '../utils/types/settings';


export const updateCompanyDetails = async (companyDetails: CompanyDetails) => {
    try {
        const response = await axios.post('/update-user', companyDetails);
        return response.data;
    } catch (error) {
        if (isAxiosError(error) && error.response) {
            console.error('Error updating company details:', error.response.data);
            throw error;
        }
        throw error; // Re-throw non-Axios errors
    }
};

export const getCompanyDetails = async (id: number) => {
    try {
        const response = await axios.post(`/get-user-by-id`, {
            id: id
        });
        return response.data;
    } catch (error) {
        if (isAxiosError(error) && error.response) {
            console.error('Error fetching company details:', error.response.data);
            throw error;
        }
        throw error;
    }
};

export const updateUserPreferences = async (preferences: UserPreferences): Promise<string | null> => {
    try {
      const response = await axios.post('/update-user-preferences', preferences);
      if (response.status === 200) {
        return "Success";
      }
      return "An error occurred";
    } catch (error) {
      if (isAxiosError(error) && error.response) {
        console.error('Error updating user preferences:', error.response.data);
      } else {
        console.error('Error updating user preferences:', error);
      }
      return null;
    }
  };

export const updateUserPassword = async (
  currentPassword: string,
  newPassword: string,
  userId: number
): Promise<string> => {
  try {
    const response = await axios.post('/update-user-password', {
      current_password: currentPassword,
      new_password: newPassword,
      id: userId
    });
    
    if (response.status === 200) {
      return "Success";
    }
    return "An error occurred";
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      console.error('Error updating user password:', error.response.data);
      if (error.response.data.error === "Invalid current password") {
        throw new Error("Invalid current password");
      }
      throw new Error(error.response.data.message || 'Failed to update password');
    }
    console.error('Error updating user password:', error);
    throw new Error('An unexpected error occurred');
  }
};

// Add this new function
export const sendInviteLink = async (companyId: number, userId: number, emails: string): Promise<string> => {
  try {
    const response = await axios.post('/send-invite-link', {
      company_id: companyId,
      user_id: userId,
      emails: emails
    });
    
    if (response.status === 200) {
      return "Invite link sent successfully";
    }
    return "An error occurred while sending the invite link";
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      console.error('Error sending invite link:', error.response.data);
      throw new Error(error.response.data.message || 'Failed to send invite link');
    }
    console.error('Error sending invite link:', error);
    throw new Error('An unexpected error occurred while sending the invite link');
  }
};

export const getUsersByCompanyId = async (companyId: number) => {
    try {
        const response = await axios.post('/get-users-by-company-id', {
            company_id: companyId
        });
        return response.data;
    } catch (error) {
        if (isAxiosError(error) && error.response) {
            console.error('Error fetching company users:', error.response.data);
            throw error;
        }
        throw error;
    }
};

export const deleteUser = async (userId: number): Promise<string> => {
  try {
    const response = await axios.post('/delete-user', {
      user_id: Number(userId)
    });
    
    if (response.status === 200) {
      return "User deleted successfully";
    }
    return "An error occurred while deleting the user";
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      console.error('Error deleting user:', error.response.data);
      throw new Error(error.response.data.message || 'Failed to delete user');
    }
    console.error('Error deleting user:', error);
    throw new Error('An unexpected error occurred while deleting the user');
  }
};
