import {
  ChatbotData,
  ChatbotPreviewResponse,
  ChatLogsResponse,
} from "../utils/types/chat";
import axios from "./axiosConfig";
import { isAxiosError } from "axios";

export const getChatbotPreview = async (
  data: ChatbotData
): Promise<ChatbotPreviewResponse> => {
  try {
    const response = await axios.post<ChatbotPreviewResponse>(
      "/chatbot-preview-gemini",
      data
    );
    return response.data;
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      console.error("Error fetching chatbot preview:", error.response.data);
    } else {
      console.error("Error fetching chatbot preview:", error);
    }
    return {
      message: "An error occurred while fetching the chatbot preview.",
    };
  }
};

interface GetChatLogsParams {
  limit: number;
  offset: number;
  domain?: string;
  search?: string;
}

export const getChatLogs = async ({
  limit,
  offset,
  domain,
  search
}: GetChatLogsParams): Promise<ChatLogsResponse | null> => {
  try {
    const response = await axios.get<ChatLogsResponse>("/chat-history", {
      params: {
        limit,
        offset,
        ...(domain && { domain }),
        ...(search && { search })
      },
    });
    return response.data;
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      console.error("Error fetching chat logs:", error.response.data);
    } else {
      console.error("Error fetching chat logs:", error);
    }
    return null;
  }
};

export const deleteChat = async (id: number): Promise<boolean> => {
  try {
    const response = await axios.delete("/chat-by-id", {
      data: { id },
    });
    if (response.status === 200) {
      return true;
    } else {
      console.error("Error deleting chat logs:", response.data);
      return false;
    }
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      console.error("Error deleting chat logs:", error.response.data);
    } else {
      console.error("Error deleting chat logs:", error);
    }
    return false;
  }
};


export const sendUserMessage = async (domain: string, session_id: string, message: string): Promise<boolean> => {
  try {
    const response = await axios.post("/send-human-message", {
      domain,
      session_id,
      message,
    });
    return response.status === 200;
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      console.error("Error sending user message:", error.response.data);
    } else {
      console.error("Error sending user message:", error);
    }
    return false;
  }
};

export const takeOverChat = async (domain: string, session_id: string): Promise<boolean> => {
  try {
    const response = await axios.post("/take-over-chat", {
      domain,
      session_id,
    });
    return response.status === 200;
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      console.error("Error taking over chat:", error.response.data);
    } else {
      console.error("Error taking over chat:", error);
    }
    return false;
  }
};

// New function for releasing a chat
export const releaseChat = async (domain: string, session_id: string): Promise<boolean> => {
  try {
    const response = await axios.post("/release-chat", {
      domain,
      session_id,
    });
    return response.status === 200;
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      console.error("Error releasing chat:", error.response.data);
    } else {
      console.error("Error releasing chat:", error);
    }
    return false;
  }
};

