import React, { useState, useEffect } from "react";
import MainLayout from "../../layouts/MainLayout";
import { FaComments, FaCommentDots, FaStar, FaSearch, FaDownload } from "react-icons/fa";
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip,
  ResponsiveContainer, PieChart, Pie, Cell, Legend, TooltipProps
} from "recharts";
import { Pagination, Table, Modal, Button, Card } from "flowbite-react";
import { getChatCounts } from "../../api/analytics";
import { ChatLog } from "../../utils/types/chat";
import { deleteChat, getChatLogs } from "../../api/chat";
import moment from "moment";
import "moment-timezone";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/helpers/authWrapper";
import Spinner from "../../components/Spinner";
import ExportCSV from "../../components/export/csv";
import { motion, AnimatePresence, Variants } from "framer-motion";

const COLORS = ["#4F46E5", "#10B981", "#F59E0B", "#EF4444"];

interface BarChartData {
  date: string;
  [key: string]: number | string;
}

interface PieChartData {
  name: string;
  value: number;
  total: number;
}

const Dashboard: React.FC = () => {
  const [barChartData, setBarChartData] = useState<BarChartData[]>([]);
  const [pieChartData, setPieChartData] = useState<PieChartData[]>([]);
  const [selectedDomain, setSelectedDomain] = useState<string>("All");
  const [logsByPage, setLogsByPage] = useState<{ [key: number]: ChatLog[] }>({});
  const [currentPage, setCurrentPage] = useState(1);
  const [logsPerPage, setLogsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [liveChats, setLiveChats] = useState(0);
  const [domainNames, setDomainNames] = useState<string[]>([]);
  const [totalChats, setTotalChats] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLogId, setSelectedLogId] = useState<number | null>(null);
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();
  const { loading, user } = useAuth();
  const [dashboardLoading, setDashboardLoading] = useState(false);
  const [averageRating, setAverageRating] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

  // Animations
  const fadeIn: Variants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 }
  };
  
  const slideUp: Variants = {
    initial: { y: 20, opacity: 0 },
    animate: { y: 0, opacity: 1 },
    exit: { y: -20, opacity: 0 }
  };

  const tableVariants: Variants = {
    hidden: { 
      opacity: 0,
      y: 20
    },
    visible: { 
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.4,
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    },
    exit: {
      opacity: 0,
      y: -20,
      transition: {
        duration: 0.3
      }
    }
  };

  const tableRowVariants: Variants = {
    hidden: { 
      opacity: 0, 
      x: -20 
    },
    visible: { 
      opacity: 1, 
      x: 0,
      transition: {
        duration: 0.3
      }
    },
    exit: { 
      opacity: 0, 
      x: 20,
      transition: {
        duration: 0.2
      }
    }
  };

  const searchInputVariants: Variants = {
    focus: { 
      scale: 1.02,
      transition: {
        duration: 0.2
      }
    },
    blur: { 
      scale: 1,
      transition: {
        duration: 0.2
      }
    }
  };

  // First useEffect for initial data load
  useEffect(() => {
    const fetchData = async () => {
      setDashboardLoading(true);
      try {
        const chatCounts = await getChatCounts(user?.id ?? 0);
        const combinedSessions: BarChartData[] = [];
        const domainNames = [
          ...new Set(chatCounts.map((item) => item.domain_name)),
        ];

        setDomainNames(domainNames);

        const dataMap: { [key: string]: BarChartData } = {};
        chatCounts.forEach((item) => {
          if (!dataMap[item.date]) {
            dataMap[item.date] = { date: item.date };
          }
          dataMap[item.date][item.domain_name] = item.count;
        });

        const last7Days = getLast7Days();
        last7Days.forEach((date) => {
          combinedSessions.push(dataMap[date] || { date });
        });

        setBarChartData(combinedSessions);

        const totalVisitors = chatCounts.reduce(
          (total, item) => total + item.count,
          0
        );

        const pieData = domainNames.map((domain_name) => {
          const domainVisitors = chatCounts
            .filter((item) => item.domain_name === domain_name)
            .reduce((sum, item) => sum + item.count, 0);
          return {
            name: domain_name,
            value: Number(((domainVisitors / totalVisitors) * 100).toFixed(2)),
            total: domainVisitors,
          };
        });

        setPieChartData(pieData.length > 1 ? pieData : []);

        if (selectedDomain === "All") {
          const totalChatsAll = chatCounts.reduce(
            (total, item) => total + item.count,
            0
          );
          setTotalChats(totalChatsAll);

          const liveChats = chatCounts[0].active_chat || 0;
          setLiveChats(liveChats);
        } else {
          const totalChatsDomain = chatCounts
            .filter((item) => item.domain_name === selectedDomain)
            .reduce((total, item) => total + item.count, 0);
          setTotalChats(totalChatsDomain);

          const filteredDomain = chatCounts.find(
            (item) => item.domain_name === selectedDomain
          );
          const liveChatsDomain = filteredDomain
            ? filteredDomain.active_chat || 0
            : 0;
          setLiveChats(liveChatsDomain);
        }

        const chatLogs = await getChatLogs({ limit: logsPerPage, offset: 0 });
        if (chatLogs) {
          setLogsByPage({ 1: chatLogs }); // Always set to page 1 initially
          const averageRating = calculateAverageRating(chatLogs);
          setAverageRating(averageRating);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setDashboardLoading(false);
      }
    };

    fetchData();  
  }, [selectedDomain, user, logsPerPage]); // Added logsPerPage

  // Second useEffect for pagination
  useEffect(() => {
    if (currentPage === 1) return; // Skip if it's the first page (handled by first useEffect)
    
    const offset = (currentPage - 1) * logsPerPage;
    const fetchChatLogs = async () => {
      const chatLogs = await getChatLogs({ limit: logsPerPage, offset });
      if (chatLogs) {
        setLogsByPage(prevLogs => ({
          ...prevLogs,
          [currentPage]: chatLogs,
        }));
        const averageRating = calculateAverageRating(chatLogs);
        setAverageRating(averageRating);
      }
    };
  
    fetchChatLogs();
  }, [logsPerPage, currentPage]);
  

  

  const calculateAverageRating = (logs: ChatLog[]) => {
    const ratedLogs = logs.filter((log) => log.rating !== "");
    const totalRating = ratedLogs.reduce(
      (sum, log) => sum + parseFloat(log.rating),
      0
    );
    return ratedLogs.length > 0
      ? (totalRating / ratedLogs.length).toFixed(2)
      : "No ratings";
  };

  const getLast7Days = () => {
    const today = new Date();
    const days = [];
    for (let i = 6; i >= 0; i--) {
      const date = new Date(today);
      date.setDate(today.getDate() - i);
      days.push(date.toISOString().split("T")[0]);
    }
    return days;
  };

  const handleDomainChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedDomain(event.target.value);
  };



  const handleLogsPerPageChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setLogsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const getFilteredData = () => {
    if (selectedDomain === "All") {
      return barChartData.map((data) => {
        const filteredData: { [key: string]: number | string } = {
          date: data.date,
        };

        domainNames.forEach((domain) => {
          filteredData[domain] = data[domain] || 0;
        });

        return filteredData;
      });
    } else {
      return barChartData.map((data) => ({
        date: data.date,
        [selectedDomain]: data[selectedDomain as keyof BarChartData] || 0,
      }));
    }
  };

  const extractNameAndEmail = (
    message: string
  ): { name: string; email: string } => {
    const userMessage = message
      .split("\n")
      .find((part) => part.startsWith("User:"));
    if (!userMessage) return { name: "Unknown", email: "Unknown" };

    const nameMatch = userMessage.match(/name is ([\w\s'.-]+) and/i);
    const emailMatch = userMessage.match(
      /email is (([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})|([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+))/i
    );

    const name = nameMatch ? nameMatch[1].trim() : "Unknown";
    const email = emailMatch ? emailMatch[1].trim() : "Unknown";

    return { name, email };
  };

  // const filteredLogs = chatLogs.filter((log) => {
  //   const { name, email } = extractNameAndEmail(log.message);
  //   return (
  //     name.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //     email.toLowerCase().includes(searchTerm.toLowerCase())
  //   );
  // });

  // const sortedLogs = [...filteredLogs].sort(
  //   (a, b) =>
  //     new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
  // );

  // const indexOfLastLog = currentPage * logsPerPage;
  // const indexOfFirstLog = indexOfLastLog - logsPerPage;
  const currentLogs = logsByPage[currentPage] || [];

  const totalPages = Math.ceil(totalChats / logsPerPage);

  const CustomTooltip: React.FC<TooltipProps<number, string>> = ({
    active,
    payload,
  }) => {
    if (active && payload && payload.length) {
      const { name, total } = payload[0].payload as PieChartData;
      return (
        <div className="bg-white dark:bg-gray-800 p-2 border rounded shadow-sm">
          <p className="text-sm font-semibold text-gray-900 dark:text-gray-200">
            {name}
          </p>
          <p className="text-sm text-gray-700 dark:text-gray-300">
            Total Visits: {total}
          </p>
        </div>
      );
    }
    return null;
  };

  const handleView = (id: number) => {
    navigate(`/chats`, { state: { id } });
  };

  const handleDelete = (id: number) => {
    setSelectedLogId(id);
    setSuccessMessage(""); // Clear any previous success message
    setIsModalOpen(true);
  };

  const confirmDelete = async () => {
    if (selectedLogId !== null) {
      const success = await deleteChat(selectedLogId);
      if (success) {
        setSuccessMessage("Chat log deleted successfully.");
        
        // Fetch updated logs after deletion
        const offset = (currentPage - 1) * logsPerPage;
        const chatLogs = await getChatLogs({ limit: logsPerPage, offset });
        setLogsByPage((prevLogs) => ({
          ...prevLogs,
          [currentPage]: chatLogs || [],
        }));
      } else {
        setSuccessMessage("Failed to delete chat log.");
      }
    }
  };
  
  // Add new debounce effect
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    return () => clearTimeout(timer);
  }, [searchTerm]);

  // Modify the search effect to handle empty search terms
  useEffect(() => {
    const fetchSearchResults = async () => {
      const chatLogs = await getChatLogs({ 
        limit: logsPerPage, 
        offset: (currentPage - 1) * logsPerPage,
        search: debouncedSearchTerm // Will be undefined/empty when search is cleared
      });

      if (chatLogs) {
        setLogsByPage({ [currentPage]: chatLogs });
      }
    };

    // Call fetchSearchResults even when search term is empty
    fetchSearchResults();
  }, [debouncedSearchTerm, logsPerPage, currentPage]);

  return (
    <MainLayout>
      <motion.div 
        className="px-4 pt-6 max-w mx-auto"
        initial="initial"
        animate="animate"
        exit="exit"
        variants={fadeIn}
      >
        {loading || dashboardLoading ? (
          <Spinner />
        ) : (
          <AnimatePresence>
            <motion.div variants={slideUp} className="space-y-8">
              {pieChartData.length > 1 && (
                <Card>
                  <div className="flex justify-between items-center">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100">
                      Domain Overview
                    </h2>
                    <select
                      id="domainSelect"
                      value={selectedDomain}
                      onChange={handleDomainChange}
                      className="p-2 border border-gray-300 dark:border-gray-700 rounded bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-200"
                    >
                      <option value="All">All Domains</option>
                      {pieChartData.map((domain) => (
                        <option key={domain.name} value={domain.name}>
                          {domain.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </Card>
              )}

              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <StatCard
                  title="Total Chats"
                  value={totalChats}
                  icon={<FaComments className="w-6 h-6 text-indigo-500" />}
                />
                <StatCard
                  title="Current Live Chats"
                  value={liveChats}
                  icon={<FaCommentDots className="w-6 h-6 text-green-500" />}
                />
                <StatCard
                  title="Average Chatbot Rating"
                  value={averageRating ? `${averageRating}/5` : "None"}
                  icon={<FaStar className="w-6 h-6 text-yellow-500" />}
                />
              </div>

              <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
                <Card className={`${pieChartData.length > 1 ? 'lg:col-span-2' : 'lg:col-span-3'}`}>
                  <h2 className="text-xl font-semibold mb-4 text-gray-900 dark:text-gray-100">
                    Last 7 Days Chat Sessions
                  </h2>
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={getFilteredData()}>
                      <CartesianGrid strokeDasharray="3 3" className="stroke-gray-300 dark:stroke-gray-700" />
                      <XAxis dataKey="date" className="text-xs text-gray-600 dark:text-gray-400" />
                      <YAxis className="text-xs text-gray-600 dark:text-gray-400" />
                      <RechartsTooltip
                        contentStyle={{
                          backgroundColor: "rgba(255, 255, 255, 0.8)",
                          borderColor: "#ccc",
                        }}
                      />
                      {Object.keys(getFilteredData()[0] || {})
                        .filter((key) => key !== "date")
                        .map((key, index) => (
                          <Bar
                            key={key}
                            dataKey={key}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                    </BarChart>
                  </ResponsiveContainer>
                </Card>
                {pieChartData.length > 1 && (
                  <Card>
                    <h2 className="text-xl font-semibold mb-4 text-gray-900 dark:text-gray-100">
                      Domain Performance
                    </h2>
                    <ResponsiveContainer width="100%" height={300}>
                      <PieChart>
                        <Pie
                          data={pieChartData}
                          cx="50%"
                          cy="50%"
                          outerRadius={80}
                          fill="#8884d8"
                          dataKey="value"
                          label={({ name, value }) => `${name}: ${value}%`}
                        >
                          {pieChartData.map((_entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                        <RechartsTooltip content={<CustomTooltip />} />
                        <Legend />
                      </PieChart>
                    </ResponsiveContainer>
                  </Card>
                )}
              </div>

              <Card>
                <div className="flex justify-between items-center">
                  <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100">
                    Chat Logs
                  </h2>
                  <ExportCSV filename="chatlogs.csv">
                    <Button color="light" size="sm">
                      <FaDownload className="mr-2" />
                      Export CSV
                    </Button>
                  </ExportCSV>
                </div>
                <div className="flex flex-col sm:flex-row justify-between items-center  sm:space-y-0">
                  <motion.div
                    initial="blur"
                    whileFocus="focus"
                    animate="blur"
                    className="relative"
                  >
                    <motion.div variants={searchInputVariants}>
                      <div className="relative">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                          <FaSearch className="w-5 h-5 text-gray-500 dark:text-gray-400" />
                        </div>
                        <input
                          type="search"
                          className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder="Search by name or email..."
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                      </div>
                    </motion.div>
                  </motion.div>
                  <select
                    value={logsPerPage}
                    onChange={handleLogsPerPageChange}
                    className="p-2 border border-gray-300 dark:border-gray-700 rounded bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-200"
                  >
                    {[10, 25, 50, 100].map((num) => (
                      <option key={num} value={num}>
                        Show {num} entries
                      </option>
                    ))}
                  </select>
                </div>
                <AnimatePresence mode="wait">
                  <motion.div
                    key={debouncedSearchTerm}
                    variants={tableVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    className="relative overflow-x-auto"
                  >
                    <Table hoverable>
                      <Table.Head>
                        <Table.HeadCell className="text-gray-900 dark:text-gray-200">SI</Table.HeadCell>
                        <Table.HeadCell className="text-gray-900 dark:text-gray-200">Name</Table.HeadCell>
                        <Table.HeadCell className="text-gray-900 dark:text-gray-200">Email</Table.HeadCell>
                        <Table.HeadCell className="text-gray-900 dark:text-gray-200">Date</Table.HeadCell>
                        <Table.HeadCell className="text-gray-900 dark:text-gray-200">Country</Table.HeadCell>
                        <Table.HeadCell className="text-gray-900 dark:text-gray-200">Rating</Table.HeadCell>
                        <Table.HeadCell className="text-gray-900 dark:text-gray-200">Actions</Table.HeadCell>
                      </Table.Head>
                      <Table.Body className="divide-y divide-gray-200 dark:divide-gray-700">
                        {currentLogs.length ? (
                          currentLogs.map((log, index) => {
                            const globalIndex = (currentPage - 1) * logsPerPage + index + 1;
                            return (
                              <motion.tr
                                key={log.id}
                                variants={tableRowVariants}
                                initial="hidden"
                                animate="visible"
                                exit="exit"
                                className="hover:bg-gray-50 dark:hover:bg-gray-700"
                              >
                                <Table.Cell className="text-gray-900 dark:text-gray-200">{globalIndex}</Table.Cell>
                                <Table.Cell className="text-gray-900 dark:text-gray-200">{extractNameAndEmail(log.message).name}</Table.Cell>
                                <Table.Cell className="text-gray-900 dark:text-gray-200">{extractNameAndEmail(log.message).email}</Table.Cell>
                                <Table.Cell className="text-gray-900 dark:text-gray-200">
                                  {moment.utc(log.created_at).tz(moment.tz.guess()).format("MMM DD, YYYY, hh:mm a")}
                                </Table.Cell>
                                <Table.Cell className="text-gray-900 dark:text-gray-200">{log.country}</Table.Cell>
                                <Table.Cell className="text-gray-900 dark:text-gray-200">
                                  {log.rating === "" ? "No rating" : log.rating}
                                </Table.Cell>
                                <Table.Cell>
                                  <Button.Group>
                                    <Button size="sm" color="info" onClick={() => handleView(log.id)}>
                                      View
                                    </Button>
                                    <Button size="sm" color="failure" onClick={() => handleDelete(log.id)}>
                                      Delete
                                    </Button>
                                  </Button.Group>
                                </Table.Cell>
                              </motion.tr>
                            );
                          })
                        ) : (
                          <motion.tr
                            variants={tableRowVariants}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                          >
                            <Table.Cell 
                              colSpan={7} 
                              className="text-center p-4 text-gray-900 dark:text-gray-200"
                            >
                              {debouncedSearchTerm 
                                ? "No matching chat logs found." 
                                : "No chat logs available."}
                            </Table.Cell>
                          </motion.tr>
                        )}
                      </Table.Body>
                    </Table>
                  </motion.div>
                </AnimatePresence>
                <div className="flex justify-center mt-4">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={(page) => setCurrentPage(page)}
                    showIcons
                  />
                </div>
              </Card>
            </motion.div>
          </AnimatePresence>
        )}
        <DeleteModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onConfirm={confirmDelete}
          successMessage={successMessage}
        />
      </motion.div>
    </MainLayout>
  );
};

const StatCard: React.FC<{ title: string; value: number | string; icon: React.ReactNode }> = ({ title, value, icon }) => (
  <Card>
    <div className="flex justify-between items-center">
      <div>
        <p className="text-sm font-medium text-gray-500 dark:text-gray-400">{title}</p>
        <p className="text-2xl font-semibold text-gray-900 dark:text-gray-100">{value}</p>
      </div>
      <div className="p-3 bg-indigo-100 rounded-full dark:bg-indigo-900">
        {icon}
      </div>
    </div>
  </Card>
);

const DeleteModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  successMessage: string;
}> = ({ isOpen, onClose, onConfirm, successMessage }) => (
  <Modal show={isOpen} onClose={onClose} size="md" popup>
    <Modal.Header />
    <Modal.Body>
      <div className="text-center">
        {successMessage ? (
          <>
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              {successMessage}
            </h3>
            <div className="flex justify-center">
              <Button color="gray" onClick={onClose}>
                Close
              </Button>
            </div>
          </>
        ) : (
          <>
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Are you sure you want to delete this chat log?
            </h3>
            <div className="flex justify-center gap-4">
              <Button color="failure" onClick={onConfirm}>
                Yes, I'm sure
              </Button>
              <Button color="gray" onClick={onClose}>
                No, cancel
              </Button>
            </div>
          </>
        )}
      </div>
    </Modal.Body>
  </Modal>
);

export default Dashboard;