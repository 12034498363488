import { isAxiosError } from "axios";
import axiosInstance from "./axiosConfig";
import {
  LoginParams,
  LoginResponse,
  CheckUserSessionResponse,
} from "../utils/types/authentication";
import { QueryClient } from '@tanstack/react-query';

interface RegisterUserParams {
  name: string;
  email: string;
  password: string;
  company_name: string;
  company_phone: string;
  company_address: string;
  company_finance_email: string;
  title: string;
}

interface RegisterNewUserParams {
  name: string;
  password: string;
  email: string;
  company_id: number;
  title: string;
}

export const AUTH_QUERY_KEY = ['auth-session'];

export const invalidateAuth = (queryClient: QueryClient) => {
  queryClient.invalidateQueries({ queryKey: AUTH_QUERY_KEY });
};

export const authenticateUser = async ({
  email,
  password,
  rememberMe,
}: LoginParams): Promise<string> => {
  try {
    const response = await axiosInstance.post<LoginResponse>("/login", {
      email,
      password,
      remember_me: rememberMe,
    });

    if (response.status === 200) {
      return "Login successful!";
    }
  } catch (error) {
    if (
      isAxiosError(error) &&
      error.response &&
      error.response.status === 401
    ) {
      return "Unauthorized: Invalid email or password";
    } else {
      return "An error occurred. Please try again later.";
    }
  }

  return "An error occurred. Please try again later.";
};

export const checkUserSession = async (): Promise<CheckUserSessionResponse> => {
  try {
    const response = await axiosInstance.get("/authenticate");
    if (response.status === 200) {
      localStorage.setItem('lastAuthCheck', JSON.stringify({
        timestamp: Date.now(),
        data: response.data
      }));
      
      return {
        authenticated: true,
        user: response.data.user,
        expired: response.data.expired,
        domains: response.data.domains,
        expiry_date: response.data.final_expiry_date,
      };
    }
  } catch (error) {
    localStorage.removeItem('lastAuthCheck');
    throw error;
  }
  return {
    authenticated: false,
    user: {
      email: "",
      name: "",
      plan_name: "",
      plan_price: 0,
      plan_id: 0,
      id: 0,
      plan_number_of_custom_domains: 0,
      desktop_notification_enabled: false,
      call_activation_enabled: false,
      email_notification_enabled: false,
      call_recording_enabled: false,
      default_language: "",
      billing_date: "",
    },
    expired: false,
    expiry_date: "",
    domains: [],
  };
};

export const logoutUser = async (): Promise<void> => {
  await axiosInstance.post("/logout");
};

export const registerUser = async ({
  name,
  email,
  password,
  company_name,
  company_phone,
  company_address,
  company_finance_email,
  title,
}: RegisterUserParams): Promise<string> => {
  try {
    const response = await axiosInstance.post("/register-company", {
      name,
      email,
      password,
      company_name,
      company_phone,
      company_address,
      company_finance_email,
      title,
    });

    if (response.status === 201) {
      return "Registration successful! Please log in.";
    }
  } catch (error) {
    if (
      isAxiosError(error) &&
      error.response &&
      error.response.status === 500
    ) {
      return error.response.data.error;
    } else {
      return "An error occurred. Please try again later.";
    }
  }

  return "An error occurred. Please try again later.";
};

export const resetPassword = async (email: string): Promise<string> => {
  try {
    const response = await axiosInstance.post("/forgot", { email });

    if (response.status === 200) {
      return "Password reset email sent!";
    }
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      if (error.response.status === 500) {
        return "Email not found in our system.";
      }
      return error.response.data.error || "An error occurred. Please try again later."; // Adjust based on actual error structure
    }
  }

  return "An error occurred. Please try again later.";
};

export const updatePassword = async (
  password: string,
  token: string
): Promise<string> => {
  try {
    const response = await axiosInstance.post("/reset", { password, token });

    if (response.status === 200) {
      return "Password updated!";
    }
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      return error.response.data;
    }
  }

  return "An error occurred. Please try again later.";
};

export const registerNewUser = async ({
  name,
  password,
  email,
  company_id,
  title,
}: RegisterNewUserParams): Promise<string> => {
  try {
    const response = await axiosInstance.post("/register-user", {
      name,
      password,
      email,
      company_id,
      title,
    });

    if (response.status === 201) {
      return "User registration successful!";
    }
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      return error.response.data.error || "An error occurred. Please try again later.";
    }
  }

  return "An error occurred. Please try again later.";
};
