import React, { useEffect, useState } from 'react';
import { useWebSocketContext } from '../../../utils/context';

interface CallWidgetProps {
  sessionId: string;
  domain: string;
  caller?: string;
  isIncoming?: boolean;
}

// Define all possible call-related message types
type CallMessageType = 
  | 'call_connected'
  | 'call_ended'
  | 'accept_call'
  | 'decline_call'
  | 'end_call'
  | 'call_hold'
  | 'call_resume';

// Base interface for all call-related messages
interface BaseCallMessage {
  type: CallMessageType;
  session_id: string;
}

// Specific message interfaces for different call states
interface CallConnectedMessage extends BaseCallMessage {
  type: 'call_connected';
  connection_data?: {
    peer_id?: string;
    room_id?: string;
  };
}

interface CallEndedMessage extends BaseCallMessage {
  type: 'call_ended';
  reason?: string;
}

// Union type for all possible call messages
type CallWebSocketMessage = 
  | CallConnectedMessage 
  | CallEndedMessage 
  | (BaseCallMessage & { 
      // For other message types that don't need additional properties
      type: Exclude<CallMessageType, 'call_connected' | 'call_ended'>;
    });

export const CallWidget: React.FC<CallWidgetProps> = ({ 
  sessionId, 
  domain, 
  caller = 'Unknown Caller',
  isIncoming = false 
}) => {
  const [duration, setDuration] = useState(0);
  const [status, setStatus] = useState<'incoming' | 'connecting' | 'connected' | 'ended'>
    (isIncoming ? 'incoming' : 'connecting');
  const { sendMessage, supportMessage } = useWebSocketContext();

  // Handle call duration
  useEffect(() => {
    let intervalId: number;
    if (status === 'connected') {
      intervalId = window.setInterval(() => {
        setDuration(prev => prev + 1);
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [status]);

  const formatDuration = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  const handleAcceptCall = () => {
    sendMessage(JSON.stringify({
      type: "accept_call",
      session_id: sessionId
    }), 'support');
    setStatus('connecting');
  };

  const handleDeclineCall = () => {
    sendMessage(JSON.stringify({
      type: "decline_call",
      session_id: sessionId
    }), 'support');
    setStatus('ended');
  };

  const handleEndCall = () => {
    sendMessage(JSON.stringify({
      type: "end_call",
      session_id: sessionId
    }), 'support');
    setStatus('ended');
  };

  useEffect(() => {
    if (supportMessage) {
      try {
        if (typeof supportMessage.data !== 'string') {
          console.error('WebSocket message data is not a string');
          return;
        }

        const data = JSON.parse(supportMessage.data) as CallWebSocketMessage;
        
        if (data.session_id === sessionId) {
          switch (data.type) {
            case 'call_connected':
              setStatus('connected');
              // You can now safely access connection_data if needed
              // const { peer_id, room_id } = data.connection_data ?? {};
              break;
            case 'call_ended':
              setStatus('ended');
              // You can now safely access reason if needed
              // console.log('Call ended reason:', data.reason);
              break;
            // Add other call status handlers as needed
          }
        }
      } catch (error) {
        console.error('Error parsing support message:', error);
      }
    }
  }, [supportMessage, sessionId]);

  return (
    <div className="bg-gray-700 rounded-lg p-4 mb-4">
      <div className="flex items-center justify-between mb-3">
        <div>
          <h3 className="text-white font-medium">{caller}</h3>
          <p className="text-gray-400 text-sm">{domain}</p>
        </div>
        {status === 'connected' && (
          <div className="text-green-400">
            {formatDuration(duration)}
          </div>
        )}
      </div>

      {status === 'incoming' ? (
        <div className="flex gap-2">
          <button
            onClick={handleAcceptCall}
            className="flex-1 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
          >
            Accept
          </button>
          <button
            onClick={handleDeclineCall}
            className="flex-1 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
          >
            Decline
          </button>
        </div>
      ) : status === 'connecting' ? (
        <div className="text-yellow-400 flex items-center justify-center">
          <svg className="animate-spin h-5 w-5 mr-2" viewBox="0 0 24 24">
            {/* Add loading spinner SVG */}
          </svg>
          Connecting...
        </div>
      ) : status === 'connected' ? (
        <div className="flex justify-between items-center">
          <button
            onClick={handleEndCall}
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
          >
            End Call
          </button>
          {/* Add other call controls (mute, etc.) */}
        </div>
      ) : (
        <div className="text-gray-400">Call ended</div>
      )}
    </div>
  );
};