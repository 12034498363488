import React, { useState, useEffect } from "react";
import { Modal, Button, Radio, Spinner } from "flowbite-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getChatHistoryByDateRange } from "../../api/analytics";
import { useAuth } from "../../utils/helpers/authWrapper";
import { CSVLink } from "react-csv";

interface ExportCSVProps {
  filename: string;
  children?: React.ReactNode;
}

interface ChatLogExport {
  name: string;
  email: string;
  country: string;
  created_at: string;
}

const ExportCSV: React.FC<ExportCSVProps> = ({ filename, children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [exportOption, setExportOption] = useState<"all" | "custom">("all");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [csvData, setCSVData] = useState<ChatLogExport[]>([]);
  const [shouldDownload, setShouldDownload] = useState(false);
  const { user } = useAuth();

  const headers = [
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Country", key: "country" },
    { label: "Date", key: "created_at" }
  ];

  const handleExport = async () => {
    setIsLoading(true);
    try {
      const params = {
        user_id: user?.id ?? 0,
        ...(exportOption === "custom" && startDate && endDate ? {
          start_date: new Date(startDate.setHours(0, 0, 0, 0)).toISOString(),
          end_date: new Date(endDate.setHours(23, 59, 59, 999)).toISOString()
        } : {})
      };

      const response = await getChatHistoryByDateRange(params);
      const formattedData = response.map(log => ({
        name: extractNameAndEmail(log.message).name,
        email: extractNameAndEmail(log.message).email,
        country: log.country,
        created_at: log.created_at
      }));
      
      setCSVData(formattedData);
      setShouldDownload(true);
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error fetching chat history:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (shouldDownload && csvData.length > 0) {
      const downloadLink = document.querySelector('.csv-download-link');
      if (downloadLink instanceof HTMLElement) {
        downloadLink.click();
      }
      setShouldDownload(false);
    }
  }, [shouldDownload, csvData]);

  const extractNameAndEmail = (message: string): { name: string; email: string } => {
    const userMessage = message.split("\n").find((part) => part.startsWith("User:"));
    if (!userMessage) return { name: "Unknown", email: "Unknown" };

    const nameMatch = RegExp(/name is ([\w\s'.-]+) and/i).exec(userMessage);
    const emailMatch = RegExp(/email is (([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})|([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+))/i).exec(userMessage);

    return {
      name: nameMatch ? nameMatch[1].trim() : "Unknown",
      email: emailMatch ? emailMatch[1].trim() : "Unknown"
    };
  };

  return (
    <>
      {children ? (
        <div onClick={() => setIsModalOpen(true)}>{children}</div>
      ) : (
        <button onClick={() => setIsModalOpen(true)} className="btn btn-primary bg-primary p-3 rounded-md">
          Export as CSV
        </button>
      )}

      {csvData.length > 0 && (
        <CSVLink
          data={csvData}
          headers={headers}
          filename={filename}
          className="csv-download-link"
          onClick={() => {
            setCSVData([]);
            setShouldDownload(false);
          }}
          style={{ display: 'none' }}
        >
          Download
        </CSVLink>
      )}

      <Modal show={isModalOpen} onClose={() => setIsModalOpen(false)} size="md" popup>
        <Modal.Header>
          <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
            Export Chat Logs
          </h3>
        </Modal.Header>
        <Modal.Body>
          {isLoading ? (
            <div className="flex justify-center">
              <Spinner />
            </div>
          ) : (
            <>
              <div className="flex flex-col gap-4">
                <div className="flex items-center">
                  <Radio
                    id="all"
                    name="exportOption"
                    value="all"
                    checked={exportOption === "all"}
                    onChange={() => setExportOption("all")}
                    className="mr-2"
                  />
                  <label htmlFor="all" className="text-gray-700 dark:text-gray-300">
                    All
                  </label>
                </div>
                <div className="flex items-center">
                  <Radio
                    id="custom"
                    name="exportOption"
                    value="custom"
                    checked={exportOption === "custom"}
                    onChange={() => setExportOption("custom")}
                    className="mr-2"
                  />
                  <label
                    htmlFor="custom"
                    className="text-gray-700 dark:text-gray-300"
                  >
                    Custom Date Range
                  </label>
                </div>
                <div className="flex gap-2">
                  <DatePicker
                    selected={startDate}
                    onChange={(date: Date | null) => setStartDate(date)}
                    disabled={exportOption === "all"}
                    placeholderText="Start Date"
                    className={`p-2 border border-gray-300 dark:border-gray-700 rounded bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-200 w-full ${
                      exportOption === "all" ? "bg-gray-200 cursor-not-allowed" : ""
                    }`}
                  />
                  <DatePicker
                    selected={endDate}
                    onChange={(date: Date | null) => setEndDate(date)}
                    disabled={exportOption === "all"}
                    placeholderText="End Date"
                    className={`p-2 border border-gray-300 dark:border-gray-700 rounded bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-200 w-full ${
                      exportOption === "all" ? "bg-gray-200 cursor-not-allowed" : ""
                    }`}
                  />
                </div>
              </div>
              <div className="flex justify-center gap-4 mt-4">
                <Button
                  color="light"
                  onClick={handleExport}
                  disabled={exportOption === "custom" && (!startDate || !endDate)}
                >
                  Export
                </Button>
                <Button color="gray" onClick={() => setIsModalOpen(false)}>
                  Cancel
                </Button>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ExportCSV;
