import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import "./App.css";
import { initGA, logPageView } from "./utils/googleanalytics";
import AuthGuard, { useAuth } from "./utils/helpers/authWrapper";
import { WebSocketProvider } from './utils/context';
import GlobalAlert from "./components/Alert";
import { CallAlert } from "./components/Alert/call_alert";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// Import all your page components
import Landing from "./pages/Landing";
import Login from "./pages/Login";
import UserSetup from "./pages/Login/user";
import ResetPW from "./pages/ResetPW";
import Dashboard from "./pages/Dashboard";
import Installation from "./pages/Installation";
import Domain from "./pages/Domain";
import AddDomain from "./pages/Domain/add";
import ViewEditDomain from "./pages/Domain/view";
import Plans from "./pages/Plans";
import Chats from "./pages/Chats";
import Train from "./pages/Domain/train";
import Billing from "./pages/Billing";
import Settings from "./pages/Settings";
import CompanyDetails from "./pages/Settings/CompanyDetails";
import ChatWidget from "./pages/Settings/ChatWidget";
import Preference from "./pages/Settings/Preference";
import RolePermissions from "./pages/Settings/RolePermissions";
import ChangePassword from "./pages/Settings/ChangePassword";
import NotFound from "./pages/NotFound";

const AppRoutes: React.FC = () => {
  const location = useLocation();
  const { authenticated } = useAuth();

  useEffect(() => {
    initGA();
    logPageView();
  }, []);

  useEffect(() => {
    logPageView();
  }, [location]);

  return (
    <Routes location={location}>
      <Route path="/" element={<Landing />} />
      <Route path="/home" element={<Landing />} />
      <Route path="/authentication" element={<Login />} />
      <Route path="/hidden-registration" element={<Login />} />
      <Route path="/join-team" element={<UserSetup />} />
      <Route path="/reset" element={<ResetPW />} />
      {authenticated && (
        <>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/installation" element={<Installation />} />
          <Route path="/domains" element={<Domain />} />
          <Route path="/domains/add" element={<AddDomain />} />
          <Route path="/domains/view" element={<ViewEditDomain />} />
          <Route path="/plans" element={<Plans />} />
          <Route path="/chats" element={<Chats />} />
          <Route path="/domains/chatbot-training" element={<Train />} />
          <Route path="/billing" element={<Billing />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/settings/company-details" element={<CompanyDetails />} />
          <Route path="/settings/chat-widget" element={<ChatWidget />} />
          <Route path="/settings/preference" element={<Preference />} />
          <Route path="/settings/role-permission" element={<RolePermissions />} />
          <Route path="/settings/change-password" element={<ChangePassword />} />
        </>
      )}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <AuthGuard>
          <WebSocketProvider>
            <AppRoutes />
            <GlobalAlert />
            <CallAlert />
          </WebSocketProvider>
        </AuthGuard>
      </Router>
    </QueryClientProvider>
  );
};

export default App;
