import React, { useEffect, useState, memo } from "react";
import type { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from '@tanstack/react-query';
import {
  Avatar,
  DarkThemeToggle,
  Dropdown,
  Navbar,
  Badge,
} from "flowbite-react";
import { rupeni } from "../../assets/images";
import { useSidebarContext } from "../context/useSideBarContext";
import { HiX, HiMenuAlt1, HiOutlineQuestionMarkCircle, HiOutlineCog, HiOutlineCreditCard, HiOutlineLogout } from "react-icons/hi";
import { useAuth } from "../../utils/helpers/authWrapper";
import { logoutUser } from "../../api/authentication";
import PlansView from "../plans";
import { Plan } from "../../utils/types/paymentplans";
import { getPlans } from "../../api/paymentplans";

const MainNavbar: React.FC = function () {
  const navigate = useNavigate();
  const { isOpenOnSmallScreens, isPageWithSidebar, setOpenOnSmallScreens } =
    useSidebarContext();
  const { user } = useAuth();
  const [showPlans, setShowPlans] = useState(false);
  const [plans, setPlans] = useState<Plan[]>([]);

  const handleBadgeClick = () => {
    setShowPlans(!showPlans);
  };

  useEffect(() => {
    getPlans().then((data) => {
      setPlans(data || []);
    });
  }, []);

  return (
    <Navbar
      fluid
      className="border-b border-b-gray-700 bg-secondary dark:bg-gray-900 fixed top-0 z-[999999] w-full"
    >
      <div className="w-full p-3 lg:px-5 lg:pl-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            {isPageWithSidebar && (
              <button
                onClick={() => setOpenOnSmallScreens(!isOpenOnSmallScreens)}
                className="mr-3 cursor-pointer rounded p-2 text-gray-400 hover:bg-gray-700 hover:text-white"
              >
                <span className="sr-only">Toggle sidebar</span>
                {isOpenOnSmallScreens ? (
                  <HiX className="h-6 w-6" />
                ) : (
                  <HiMenuAlt1 className="h-6 w-6" />
                )}
              </button>
            )}
            <Navbar.Brand as="div" onClick={() => navigate("/")} className="flex items-center cursor-pointer">
              <img
                alt="Rupeni AI Logo"
                src={rupeni}
                className="mr-3 h-6 sm:h-8 w-auto"
              />
              <span className="self-center whitespace-nowrap text-2xl text-white font-semibold hidden lg:block">
                Rupeni AI
              </span>
            </Navbar.Brand>
          </div>
          <div className="flex items-center lg:gap-3">
            {user && (
              <div className="relative">
                <Badge
                  color="info"
                  className="text-sm py-2 px-2 cursor-pointer"
                  onClick={handleBadgeClick}
                >
                  {user.plan_name} Plan
                </Badge>
                {showPlans && (
                  <PlansView plans={plans} selectedPlanId={user.plan_id} />
                )}
              </div>
            )}
            <div className="flex items-center">
              <DarkThemeToggle />
            </div>
            <div className="lg:block">
              <UserDropdown />
            </div>
          </div>
        </div>
      </div>
    </Navbar>
  );
};

const UserDropdown: FC = memo(function () {
  const { user, domains } = useAuth();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleLogout = async () => {
    try {
      await logoutUser();
      await queryClient.invalidateQueries({ queryKey: ['auth-session'] });
      queryClient.removeQueries({ queryKey: ['auth-session'] });
      queryClient.clear();
      navigate("/authentication");
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const theme = {
    arrowIcon: "ml-2 h-4 w-4 text-gray-400",
    content: "py-1 focus:outline-none",
    floating: {
      animation: "transition-opacity",
      arrow: {
        base: "absolute z-10 h-2 w-2 rotate-45",
        style: {
          dark: "bg-gray-900",
          light: "bg-white",
          auto: "bg-white dark:bg-gray-900"
        },
        placement: "-4px"
      },
      base: "z-10 w-fit rounded divide-y divide-gray-100 shadow",
      content: "py-1 text-sm text-gray-700 dark:text-gray-200",
      divider: "my-1 h-px bg-gray-100 dark:bg-gray-600",
      header: "block py-2 px-4 text-sm text-gray-700 dark:text-gray-200",
      hidden: "invisible opacity-0",
      item: {
        base: "flex items-center justify-start py-2 px-4 text-sm text-gray-700 cursor-pointer hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-600 focus:outline-none",
        icon: "mr-2 h-4 w-4"
      },
      style: {
        auto: "border border-gray-200 bg-white text-gray-900 dark:border-gray-600 dark:bg-gray-700 dark:text-white"
      },
      target: "w-fit"
    }
  };

  return (
    <Dropdown
      arrowIcon={true}
      inline
      label={
        <Avatar
          alt={user?.name}
          img={domains && domains.length > 0 ? domains[0].chat_bot_logo : undefined}
          rounded
          size="sm"
          bordered
          color="light"
        />
      }
      theme={theme}
    >
      <Dropdown.Header>
        <span className="block text-sm font-semibold">{user?.name}</span>
        <span className="block truncate text-sm font-normal text-gray-500 dark:text-gray-400">
          {user?.email}
        </span>
      </Dropdown.Header>
      <Dropdown.Item as="div" onClick={() => navigate("/installation")} icon={HiOutlineQuestionMarkCircle}>
        How-to guide
      </Dropdown.Item>
      <Dropdown.Item as="div" onClick={() => navigate("/settings")} icon={HiOutlineCog}>
        Settings
      </Dropdown.Item>
      <Dropdown.Item as="div" onClick={() => navigate("/billing")} icon={HiOutlineCreditCard}>
        Billing
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item onClick={handleLogout} className="w-full" icon={HiOutlineLogout}>
        Sign out 
      </Dropdown.Item>
    </Dropdown>
  );
});


export default MainNavbar;
