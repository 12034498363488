import React, { useState, useEffect } from "react";
import MainLayout from "../../layouts/MainLayout";
import { Button, Card } from "flowbite-react";
import {
  initiatePayment,
  getStatementsByUserId,
  addNewPaymentByUserId,
  updatePaymentStatus,
  getPaymentsByUserId,
} from "../../api/paymentplans";
import { useAuth } from "../../utils/helpers/authWrapper";
import { motion, AnimatePresence } from "framer-motion";
import { getCardTypeInfo } from "../../utils/helpers/getCardType";

// Define the interface once at the top level
export interface Statement {
  amount: number;
  id: number;
  user_id: number;
  created_at: string;
  plan_name: string;
  plan_price: number;
}

export interface Payment {
  id: number;
  user_id: number;
  created_at: string;
  amount: number;
  status: string;
  masked_card_number?: string | null;
}

function CardDisplay({ maskedNumber }: { maskedNumber: string | null }) {
  const cardInfo = getCardTypeInfo(maskedNumber);
  const lastFour = maskedNumber ? maskedNumber.slice(-4) : "";
  const Icon = cardInfo.icon;

  return (
    <div className="flex items-center space-x-3">
      <Icon className="h-5 w-7 " />
      <div className="text-white">
        <span className="font-medium">{cardInfo.name}</span>
        {lastFour && <span className="ml-2">**** {lastFour}</span>}
      </div>
    </div>
  );
}

// Add this helper function near your other helpers
const formatExpiryDate = (dateString: string) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
};

// Add this helper function near your other helpers
const formatBillingPeriod = (billingDate: string) => {
  const startDate = new Date(billingDate);
  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 30);

  return `${startDate.getDate()} ${startDate.toLocaleString("en-US", {
    month: "long",
  })} ${startDate.getFullYear()} - ${endDate.getDate()} ${endDate.toLocaleString(
    "en-US",
    { month: "long" }
  )} ${endDate.getFullYear()}`;
};

// Add these constants at the top of your file
const WS_USERNAME = "rupeni";
const WS_PASSWORD = "rupeni";

function Billing() {
  const [statementsPage, setStatementsPage] = useState(1);
  const [paymentHistoryPage, setPaymentHistoryPage] = useState(1);
  const [isProcessing, setIsProcessing] = useState(false);
  const { user, expiryDate } = useAuth();

  const [notification, setNotification] = useState<{
    show: boolean;
    message: string;
    type: "success" | "error";
  }>({ show: false, message: "", type: "success" });

  const [isPaymentPending, setIsPaymentPending] = useState(false);
  const [currentOrderId, setCurrentOrderId] = useState<string | null>(null);
  const [statements, setStatements] = useState<Statement[]>([]);

  // Add state to store the current payment ID
  const [currentPaymentId, setCurrentPaymentId] = useState<number | null>(null);

  // Add state for payments
  const [payments, setPayments] = useState<Payment[]>([]);

  // Add new payment to the table
  const addPaymentToTable = (newPayment: Payment) => {
    setPayments((prevPayments) => [newPayment, ...prevPayments]); // Add to start of array
  };

  // Calculate total balance due from all statements
  const totalBalanceDue = statements.reduce((total, statement) => {
    return total + statement.amount;
  }, 0);

  // Modified WebSocket connection
  useEffect(() => {
    const encodedAuth = btoa(`${WS_USERNAME}:${WS_PASSWORD}`);
    const ws = new WebSocket(
      `wss://payments.vaizee.com/ws?auth=${encodedAuth}`
    );

    ws.onmessage = async (event) => {
      const data = JSON.parse(event.data);
      console.log(data);
      console.log(data.type);
      if (
        data.type === "payment_status" &&
        data.data.orderId === currentOrderId
      ) {
        setIsPaymentPending(false);

        if (currentPaymentId) {
          const updatedPayment = await updatePaymentStatus(
            currentPaymentId,
            data.data.status === "approved" ? "complete" : "declined",
            data.data.maskedPan || null,
            data.data.virtualPan || null
          );

          if (updatedPayment?.payment) {
            setPayments((prevPayments) =>
              prevPayments.map((payment) =>
                payment.id === currentPaymentId
                  ? updatedPayment.payment
                  : payment
              )
            );
          }
        }

        if (data.data.status === "approved") {
          setNotification({
            show: true,
            message: "Payment processed successfully!",
            type: "success",
          });
          setCurrentOrderId(null);
          setCurrentPaymentId(null);
        } else if (data.data.status === "declined") {
          setNotification({
            show: true,
            message: "Payment was declined. Please try again.",
            type: "error",
          });
          setCurrentOrderId(null);
          setCurrentPaymentId(null);
        }
      }
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
      setIsPaymentPending(false);
    };

    return () => {
      ws.close();
    };
  }, [currentOrderId, currentPaymentId]);

  // Add useEffect to fetch statements
  useEffect(() => {
    const fetchStatements = async () => {
      if (user?.id) {
        const response = await getStatementsByUserId(user.id);
        console.log("Fetched statements:", response);
        // Now this should work without type errors
        setStatements(response?.statements || []);
      }
    };

    fetchStatements();
  }, [user?.id]); // Dependency on user.id ensures it runs when user is loaded

  // Add useEffect to fetch payments
  useEffect(() => {
    const fetchPayments = async () => {
      if (user?.id) {
        const response = await getPaymentsByUserId(user.id);
        console.log("Fetched payments:", response);
        // Sort payments by date (most recent first)
        const sortedPayments = (response?.payments || []).sort(
          (a, b) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
        setPayments(sortedPayments);
      }
    };

    fetchPayments();
  }, [user?.id]); // Dependency on user.id ensures it runs when user is loaded

  function PaymentStatusOverlay() {
    if (!isPaymentPending && !notification.show) return null;

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      >
        <AnimatePresence mode="wait">
          {isPaymentPending ? (
            <motion.div
              key="processing"
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              transition={{ type: "spring", duration: 0.5 }}
              className="bg-white dark:bg-gray-800 p-10 rounded-xl shadow-2xl w-[40rem] min-h-[20rem] relative"
            >
              <div className="flex flex-col items-center justify-center h-full space-y-8">
                <div className="w-20 h-20 rounded-full bg-blue-100 dark:bg-blue-900 flex items-center justify-center">
                  <motion.svg
                    animate={{ rotate: 360 }}
                    transition={{
                      duration: 2,
                      repeat: Infinity,
                      ease: "linear",
                    }}
                    className="w-12 h-12 text-blue-500"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                  </motion.svg>
                </div>
                <div className="text-center space-y-4">
                  <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
                    Processing Your Payment
                  </h2>
                  <div className="space-y-2">
                    <p className="text-lg text-gray-600 dark:text-gray-300">
                      Please keep this window open while we process your payment
                    </p>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      This usually takes less than a minute
                    </p>
                  </div>
                </div>
              </div>
            </motion.div>
          ) : (
            <motion.div
              key="notification"
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              transition={{ type: "spring", duration: 0.5 }}
              className={`bg-white dark:bg-gray-800 p-10 rounded-xl shadow-2xl w-[40rem] min-h-[20rem] relative
                ${
                  notification.type === "success"
                    ? "border-l-8 border-green-500"
                    : "border-l-8 border-red-500"
                }`}
            >
              <div className="flex flex-col items-center justify-center h-full space-y-8">
                <div
                  className={`w-20 h-20 rounded-full 
                  ${
                    notification.type === "success"
                      ? "bg-green-100 dark:bg-green-900"
                      : "bg-red-100 dark:bg-red-900"
                  } 
                  flex items-center justify-center`}
                >
                  <motion.svg
                    initial={{ scale: 0 }}
                    animate={{ scale: 1, rotate: [0, 20, 0] }}
                    transition={{ type: "spring", duration: 0.8 }}
                    className={`w-12 h-12 
                      ${
                        notification.type === "success"
                          ? "text-green-500"
                          : "text-red-500"
                      }`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    {notification.type === "success" ? (
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2.5}
                        d="M5 13l4 4L19 7"
                      />
                    ) : (
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2.5}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    )}
                  </motion.svg>
                </div>
                <div className="text-center space-y-4">
                  <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
                    {notification.type === "success"
                      ? "Payment Successful!"
                      : "Payment Failed"}
                  </h2>
                  <p className="text-lg text-gray-600 dark:text-gray-300">
                    {notification.message}
                  </p>
                  {notification.type === "success" && (
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      A confirmation email will be sent to your registered email
                      address
                    </p>
                  )}
                </div>
                <motion.button
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.5 }}
                  onClick={() =>
                    setNotification((prev) => ({ ...prev, show: false }))
                  }
                  className={`mt-6 px-6 py-2 rounded-lg font-medium transition-colors
                    ${
                      notification.type === "success"
                        ? "bg-green-100 text-green-700 hover:bg-green-200 dark:bg-green-900 dark:text-green-100"
                        : "bg-red-100 text-red-700 hover:bg-red-200 dark:bg-red-900 dark:text-red-100"
                    }`}
                >
                  {notification.type === "success" ? "Done" : "Try Again"}
                </motion.button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    );
  }

  const itemsPerPage = 5;

  function Pagination({
    currentPage,
    pageCount,
    setPage,
  }: {
    currentPage: number;
    pageCount: number;
    setPage: (page: number) => void;
  }) {
    return (
      <div className="flex justify-center items-center mt-4 ">
        {Array.from({ length: pageCount }, (_, i) => i + 1).map(
          (pageNumber) => (
            <button
              key={pageNumber}
              onClick={() => setPage(pageNumber)}
              className={`px-3 py-1 text-sm ${
                currentPage === pageNumber
                  ? "font-bold underline text-blue-600 dark:text-blue-400"
                  : "text-gray-600 dark:text-gray-400 hover:text-blue-600 dark:hover:text-blue-400"
              }`}
            >
              {pageNumber}
            </button>
          )
        )}
      </div>
    );
  }

  const handlePayNow = async () => {
    try {
      setIsProcessing(true);

      const customerName = user?.name ?? "Unknown Customer";
      const amount = totalBalanceDue.toFixed(2);
      const orderId = Date.now().toString();
      setCurrentOrderId(orderId);

      const paymentResponse = await initiatePayment(customerName, amount);

      if (paymentResponse?.paymentLink && user?.id) {
        const newPayment = await addNewPaymentByUserId(
          user.id,
          parseFloat(amount),
          "pending"
        );

        console.log("New payment:", newPayment);

        if (newPayment?.payment) {
          setCurrentPaymentId(newPayment.payment.id);
          // Add the new payment to the table
          addPaymentToTable(newPayment.payment);
          window.open(paymentResponse.paymentLink);
          setIsProcessing(false);
          setIsPaymentPending(true);
        } else {
          throw new Error("Failed to record payment");
        }
      }
    } catch (error) {
      console.error("Payment process failed:", error);
      setIsProcessing(false);
      setIsPaymentPending(false);
      setNotification({
        show: true,
        message: "Failed to process payment. Please try again.",
        type: "error",
      });
    }
  };

  // Helper function to format date in "D MMM YYYY" format
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date
      .toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
      .split(" ")
      .join(" ")
      .toUpperCase();
  };

  // Get the most recent successful payment's card info
  const getLatestSuccessfulCard = () => {
    const successfulPayment = payments.find((p) => p.status === "complete");
    return successfulPayment?.masked_card_number || null;
  };

  return (
    <MainLayout>
      <PaymentStatusOverlay />
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-6 px-4 py-6">
        <div className="lg:col-span-1 space-y-4">
          <Card className="px-4 py-2 bg-[#383838] dark:bg-gray-800 text-white text-center">
            <h1 className="text-gray-200 dark:text-gray-300">BALANCE DUE</h1>
            <h1 className="text-2xl font-bold">
              ${totalBalanceDue.toFixed(2)}
            </h1>
            <h1 className="text-gray-200 dark:text-gray-300">
              {user?.plan_name}
            </h1>
            <Button
              className="rounded-md bg-primary text-black dark:text-white w-full mt-2 hover:text-white dark:hover:bg-blue-600"
              onClick={handlePayNow}
              disabled={isProcessing}
            >
              {isProcessing ? (
                <div className="flex items-center justify-center">
                  <svg
                    className="animate-spin h-5 w-5 mr-3"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                  </svg>
                  Processing...
                </div>
              ) : (
                "Pay Now"
              )}
            </Button>
          </Card>

          <Card className="px-4 py-2 bg-[#383838] dark:bg-gray-800 text-white text-start">
            <h1 className="font-bold">AUTOMATIC PAYMENT METHOD</h1>

            <CardDisplay maskedNumber={getLatestSuccessfulCard()} />
            <hr className="my-3 border-t-2 border-white dark:border-gray-600" />

            <h1 className="font-bold">Current Billing Cycle:</h1>

            <div className="flex flex-col">
              <span className="text-gray-200 dark:text-gray-300">
                Billing Period:{" "}
                <span className="text-white dark:text-gray-100 font-bold">
                  {user?.billing_date
                    ? formatBillingPeriod(user.billing_date)
                    : "Not available"}
                </span>
              </span>
              <span className="text-gray-200 dark:text-gray-300">
                Payment Due Date:{" "}
                <span className="text-white dark:text-gray-100 font-bold">
                  {expiryDate ? formatExpiryDate(expiryDate) : "Not available"}
                </span>
              </span>
            </div>

            <span className="text-gray-200 dark:text-gray-300 text-xs mt-2 block">
              *Your next payment will be due on the first day of your billing
              cycle
            </span>
          </Card>
        </div>
        <div className="lg:col-span-3 space-y-6">
          <Card className="p-4 dark:bg-gray-800 bg-white">
            <h1 className="text-2xl font-bold text-start text-gray-900 dark:text-gray-100 mb-4">
              STATEMENTS
            </h1>
            <div className="overflow-x-auto">
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400">
                  <tr className="border-b-2 border-gray-300">
                    <th scope="col" className="font-normal pb-3">
                      INVOICE DATE
                    </th>
                    <th scope="col" className="font-normal px-6 pb-3">
                      BALANCE DUE
                    </th>
                    <th scope="col" className="font-normal px-6 pb-3">
                      PLAN
                    </th>
                    <th scope="col" className="font-normal px-6 pb-3">
                      TOTAL
                    </th>
                    <th
                      scope="col"
                      className="font-normal px-6 pb-3 text-center"
                    >
                      DOWNLOAD
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {statements
                    .slice(
                      (statementsPage - 1) * itemsPerPage,
                      statementsPage * itemsPerPage
                    )
                    .map((statement, index, array) => (
                      <tr
                        key={statement.id}
                        className={`font-bold text-black dark:text-white ${
                          index !== array.length - 1
                            ? "border-b-2 border-gray-300 dark:border-gray-700"
                            : ""
                        }`}
                      >
                        <td className="py-4">
                          {formatDate(statement.created_at)}
                        </td>
                        <td className="px-6 py-4">
                          ${statement.amount.toFixed(2)}
                        </td>
                        <td className="px-6 py-4">{statement.plan_name}</td>
                        <td className="px-6 py-4">
                          ${statement.plan_price.toFixed(2)}
                        </td>
                        <td className="px-6 py-4">
                          <div className="flex justify-center items-center h-full">
                            <button
                              className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 transition-colors duration-200"
                              aria-label="Download invoice"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5 text-blue-500"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                                />
                              </svg>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <Pagination
              currentPage={statementsPage}
              pageCount={Math.ceil((statements || []).length / itemsPerPage)}
              setPage={setStatementsPage}
            />
          </Card>

          <Card className="p-4 dark:bg-gray-800 bg-white">
            <h1 className="text-2xl font-bold text-start mb-4 text-gray-900 dark:text-gray-100">
              PAYMENT HISTORY
            </h1>
            <div className="overflow-x-auto">
              <div className="w-full grid grid-cols-3 text-sm text-gray-500 dark:text-gray-400">
                {payments
                  .slice(
                    (paymentHistoryPage - 1) * itemsPerPage,
                    paymentHistoryPage * itemsPerPage
                  )
                  .map((payment, index, array) => (
                    <React.Fragment key={payment.id}>
                      <div
                        className={`py-4 font-bold text-black dark:text-white ${
                          index !== array.length - 1
                            ? "border-b-2 border-gray-300 dark:border-gray-700"
                            : ""
                        }`}
                      >
                        {formatDate(payment.created_at)}
                      </div>
                      <div
                        className={`py-4 text-center font-bold text-black dark:text-white ${
                          index !== array.length - 1
                            ? "border-b-2 border-gray-300 dark:border-gray-700"
                            : ""
                        }`}
                      >
                        ${payment.amount.toFixed(2)}
                      </div>
                      <div
                        className={`py-4 text-right font-bold text-black dark:text-white ${
                          index !== array.length - 1
                            ? "border-b-2 border-gray-300 dark:border-gray-700"
                            : ""
                        }`}
                      >
                        {payment.status.toUpperCase()}
                      </div>
                    </React.Fragment>
                  ))}
              </div>
            </div>
            <Pagination
              currentPage={paymentHistoryPage}
              pageCount={Math.ceil(payments.length / itemsPerPage)}
              setPage={setPaymentHistoryPage}
            />
          </Card>
        </div>
      </div>
    </MainLayout>
  );
}

export default Billing;
