import { Button, Spinner } from "flowbite-react";
import React, { useState } from "react";
import { registerUser } from "../../api/authentication";
import { motion, AnimatePresence } from "framer-motion";

interface RegisterComponentProps {
  setTab: (tab: string) => void;
}

const RegisterComponent: React.FC<RegisterComponentProps> = ({ setTab }) => {
  const [step, setStep] = useState(1);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyPhone, setCompanyPhone] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyFinanceEmail, setCompanyFinanceEmail] = useState("");
  const [title, setTitle] = useState("");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [success, setSuccess] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleNextStep = () => {
    const newErrors: { [key: string]: string } = {};

    if (!validateEmail(email)) {
      newErrors.email = "Invalid email format";
    }
    if (password !== confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setStep(2);
    }
  };

  const handlePrevStep = () => {
    setStep(1);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const newErrors: { [key: string]: string } = {};

    if (!validateEmail(companyFinanceEmail)) {
      newErrors.companyFinanceEmail = "Invalid email format";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setLoading(true);
      try {
        const response = await registerUser({
          name,
          email,
          password,
          company_name: companyName,
          company_phone: companyPhone,
          company_address: companyAddress,
          company_finance_email: companyFinanceEmail,
          title,
        });
        setSuccess(response);
      } catch (error) {
        setErrors({
          apiError: error instanceof Error ? error.message : "An error occurred. Please try again later.",
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const formVariants = {
    enter: (direction: number) => ({
      x: direction > 0 ? 300 : -300,
      opacity: 0,
    }),
    center: {
      x: 0,
      opacity: 1,
    },
    exit: (direction: number) => ({
      x: direction < 0 ? 300 : -300,
      opacity: 0,
    })
  };

  return (
    <div className="flex items-center justify-center lg:col-span-3">
      <div className="bg-white p-10 rounded-lg shadow-lg w-full max-w-lg relative z-10 animate-fade-in">
        <h2 className="text-3xl font-bold text-center mb-8 text-gray-800 animate-slide-in">
          Create Your Account
        </h2>
        {success ? (
          <div className="flex flex-col items-center justify-center text-center">
            <p className="text-green-500 mb-4" aria-live="polite">
              {success}
            </p>
            <Button
              className="bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-600 hover:to-purple-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-300 transform hover:scale-105"
              onClick={() => setTab("login")}
            >
              Login Now
            </Button>
          </div>
        ) : (
          <div className="grid grid-cols-1 grid-rows-1">
            <AnimatePresence initial={false} custom={step}>
              <motion.div
                key={step}
                custom={step}
                variants={formVariants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{
                  x: { type: "spring", stiffness: 300, damping: 30 },
                  opacity: { duration: 0.2 }
                }}
                className="col-start-1 col-end-2 row-start-1 row-end-2"
              >
                <form onSubmit={handleSubmit} className="w-full">
                  {step === 1 ? (
                    <>
                      <InputField label="Name" id="name" value={name} onChange={setName} icon="user" />
                      <InputField label="Email" id="email" value={email} onChange={setEmail} icon="envelope" error={errors.email} />
                      <InputField label="Password" id="password" value={password} onChange={setPassword} icon="lock" type="password" />
                      <InputField label="Confirm Password" id="confirmPassword" value={confirmPassword} onChange={setConfirmPassword} icon="lock" type="password" error={errors.confirmPassword} />
                      <div className="flex justify-end mt-6">
                        <Button onClick={handleNextStep}>Next</Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <InputField label="Company Name" id="companyName" value={companyName} onChange={setCompanyName} icon="building" />
                      <InputField label="Company Phone" id="companyPhone" value={companyPhone} onChange={setCompanyPhone} icon="phone" />
                      <InputField label="Company Address" id="companyAddress" value={companyAddress} onChange={setCompanyAddress} icon="map-marker-alt" />
                      <InputField label="Company Finance Email" id="companyFinanceEmail" value={companyFinanceEmail} onChange={setCompanyFinanceEmail} icon="envelope" error={errors.companyFinanceEmail} />
                      <InputField label="Your Title" id="title" value={title} onChange={setTitle} icon="briefcase" />
                      <div className="flex justify-between mt-6">
                        <Button onClick={handlePrevStep}>Previous</Button>
                        <Button type="submit" disabled={loading}>
                          {loading ? (
                            <>
                              <Spinner aria-label="Spinner" size="sm" />
                              <span className="pl-3">Loading...</span>
                            </>
                          ) : (
                            "Register"
                          )}
                        </Button>
                      </div>
                    </>
                  )}
                  {errors.apiError && (
                    <p className="text-red-500 text-xs mt-1" aria-live="polite">
                      {errors.apiError}
                    </p>
                  )}
                </form>
              </motion.div>
            </AnimatePresence>
          </div>
        )}
        <div className="text-center mt-8 animate-slide-in delay-700">
          <p className="text-sm text-gray-500">
            Already have an account?{" "}
            <button
              onClick={() => setTab("login")}
              className="text-blue-500 hover:text-blue-700 cursor-pointer"
            >
              Login
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};

// Helper component for input fields
const InputField: React.FC<{
  label: string;
  id: string;
  value: string;
  onChange: (value: string) => void;
  icon: string;
  type?: string;
  error?: string;
}> = ({ label, id, value, onChange, icon, type = "text", error }) => (
  <div className="mb-4">
    <label htmlFor={id} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
      {label}
    </label>
    <div className="relative">
      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
        <i className={`fas fa-${icon} text-gray-400`}></i>
      </div>
      <input
        type={type}
        id={id}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        required
      />
    </div>
    {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
  </div>
);

export default RegisterComponent;
