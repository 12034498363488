import React, { useState, useRef, useEffect } from "react";
import { Card, Button } from "flowbite-react";
import { HiUserGroup, HiUserCircle, HiChevronDown, HiTrash, HiX } from "react-icons/hi";
import MainLayout from "../../layouts/MainLayout";
import { useAuth } from "../../utils/helpers/authWrapper";
import { sendInviteLink, getCompanyDetails, getUsersByCompanyId, deleteUser } from "../../api/settings";
import { Breadcrumb } from '../../components/Breadcrumb';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


interface User {
  id: number;
  name: string;
  email: string;
  role: string;
  status?: string;
  isOwner?: boolean;
}

const UsersTable: React.FC<{ 
  users: User[];
  openDropdown: number | null;
  toggleDropdown: (userId: number, event: React.MouseEvent) => void;
}> = ({ users, openDropdown, toggleDropdown }) => {
  if (users.length === 0) {
    return (
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs sm:text-base text-gray-700 dark:text-gray-300 border-b-2 border-gray-300 dark:border-gray-700">
          <tr>
            <th scope="col" className="font-normal pb-3">Name</th>
            <th scope="col" className="font-normal px-2 sm:px-6 pb-3">Role</th>
            <th scope="col" className="font-normal px-2 sm:px-6 pb-3">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={3} className="text-center py-4">No users found</td>
          </tr>
        </tbody>
      </table>
    );
  }

  return (
    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
      <thead className="text-xs sm:text-base text-gray-700 dark:text-gray-300 border-b-2 border-gray-300 dark:border-gray-700">
        <tr>
          <th scope="col" className="font-normal pb-3">Name</th>
          <th scope="col" className="font-normal px-2 sm:px-6 pb-3">Role</th>
          <th scope="col" className="font-normal px-2 sm:px-6 pb-3">Status</th>
        </tr>
      </thead>
      <tbody>
        {users.map((user, index) => (
          <tr
            key={user.id}
            className={`font-bold text-black dark:text-white ${
              index !== users.length - 1 ? "border-b-2 border-gray-300 dark:border-gray-700" : ""
            }`}
          >
            <td className="py-4">
              <div className="flex items-center">
                <HiUserCircle className="w-8 h-8 sm:w-10 sm:h-10 text-gray-400 dark:text-gray-500 mr-2 sm:mr-3" />
                <div>
                  <div className="font-bold text-xs sm:text-sm">
                    {user.name} {user.isOwner && "(You)"}
                  </div>
                  <div className="text-xs sm:text-sm font-normal text-gray-500 dark:text-gray-400">
                    {user.email}
                  </div>
                </div>
              </div>
            </td>
            <td className="px-2 sm:px-6 py-4">
              <span className="bg-blue-800 dark:bg-blue-600 py-1 px-2 sm:px-3 rounded-md text-white font-normal text-xs sm:text-sm">
                {user.role}
              </span>
            </td>
            <td className="px-2 sm:px-6 py-4">
              <div className="relative">
                <button
                  onClick={(e) => toggleDropdown(user.id, e)}
                  className="flex items-center space-x-1 sm:space-x-2 cursor-pointer focus:outline-none"
                >
                  <span className="w-2 h-2 bg-green-500 rounded-full"></span>
                  <span className="text-xs sm:text-sm">{user.status}</span>
                  <HiChevronDown
                    className={`text-gray-500 dark:text-gray-400 transition-transform duration-200 ${
                      openDropdown === user.id ? "transform rotate-180" : ""
                    }`}
                  />
                </button>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const RolePermissions: React.FC = () => {
  const [openDropdown, setOpenDropdown] = useState<number | null>(null);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [inviteEmails, setInviteEmails] = useState("");
  const [inviteRole, setInviteRole] = useState("Admin");
  const [isRoleDropdownOpen, setIsRoleDropdownOpen] = useState(false);
  const roleDropdownRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const { user } = useAuth();
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [companyId, setCompanyId] = useState<number | null>(null);
  const [users, setUsers] = useState<User[]>([]);
  const [isUsersLoading, setIsUsersLoading] = useState(false);
  const [usersError, setUsersError] = useState<string | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState<number | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchCompanyId = async () => {
      if (user?.id) {
        try {
          const userDetails = await getCompanyDetails(user.id);
          setCompanyId(userDetails.company_id);
        } catch (error) {
          console.error("Error fetching user details:", error);
        }
      }
    };

    fetchCompanyId();
  }, [user]);

  useEffect(() => {
    const fetchUsers = async () => {
      if (!companyId) return;
      
      setIsUsersLoading(true);
      setUsersError(null);
      
      try {
        const response = await getUsersByCompanyId(companyId);
        const formattedUsers = response.map((user: User) => ({
          id: Number(user.id),
          name: user.name,
          email: user.email,
          role: user.role,
          status: "Accepting chats",
          isOwner: user.id === user?.id
        }));
        setUsers(formattedUsers);
      } catch (error) {
        console.error("Error fetching users:", error);
        setUsersError("Failed to load users");
      } finally {
        setIsUsersLoading(false);
      }
    };

    fetchUsers();
  }, [companyId, user?.id]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        tableRef.current &&
        dropdownRef.current &&
        !tableRef.current.contains(event.target as Node) &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        setIsInviteModalOpen(false);
      }
    };

    if (isInviteModalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isInviteModalOpen]);

  const toggleDropdown = (userId: number, event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (openDropdown === userId) {
      setOpenDropdown(null);
    } else {
      const rect = (event.currentTarget as HTMLElement).getBoundingClientRect();
      setDropdownPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
      });
      setOpenDropdown(userId);
    }
  };

  const handleDelete = (userId: number, event: React.MouseEvent) => {
    event.stopPropagation();
    if (userId === user?.id) {
      toast.error("You cannot delete your own account");
      return;
    }
    setOpenDropdown(null);
    setUserToDelete(userId);
    setIsDeleteModalOpen(true);
  };

  const confirmDelete = async () => {
    if (!userToDelete) return;
    
    try {
      await deleteUser(userToDelete);
      if (companyId) {
        const response = await getUsersByCompanyId(companyId);
        const formattedUsers = response.map((user: User) => ({
          id: Number(user.id),
          name: user.name,
          email: user.email,
          role: user.role,
          status: "Accepting chats",
          isOwner: user.id === user?.id
        }));
        setUsers(formattedUsers);
      }
      setIsDeleteModalOpen(false);
      toast.success("User deleted successfully", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error(error instanceof Error ? error.message : "Failed to delete user", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } finally {
      setUserToDelete(null);
    }
  };

  const cleanEmails = (emails: string): string => {
    return emails
      .split(',')
      .map(email => email.trim())
      .filter(email => email.length > 0)
      .join(',');
  };

  const handleInvite = async () => {
    setIsLoading(true);
    setError(null);
    const cleanedEmails = cleanEmails(inviteEmails);

    if (!user?.id || !companyId) {
      setError("User ID or Company ID is not available. Please ensure you're logged in.");
      setIsLoading(false);
      return;
    }

    try {
      const result = await sendInviteLink(companyId, user.id, cleanedEmails);
      console.log(result);
      setIsInviteModalOpen(false);
      setInviteEmails("");
      setInviteRole("Admin");
    } catch (error) {
      setError(error instanceof Error ? error.message : "An error occurred while sending invites");
    } finally {
      setIsLoading(false);
    }
  };

  const renderContent = () => {
    if (isUsersLoading) {
      return <div className="text-center py-4">Loading users...</div>;
    }
    
    if (usersError) {
      return <div className="text-red-500 text-center py-4">{usersError}</div>;
    }
    
    return (
      <UsersTable 
        users={users} 
        openDropdown={openDropdown} 
        toggleDropdown={toggleDropdown}
      />
    );
  };

  return (
    <MainLayout>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="px-4 py-8">
        <Breadcrumb 
          items={[
            { label: 'Role Permissions', path: '/settings/role-permissions' }
          ]} 
        />
        <Card className="p-4 bg-white dark:bg-gray-800 overflow-visible ">
          <div className="flex flex-col gap-5">
            <div className="flex items-center gap-4">
              <HiUserGroup className="w-8 h-8 text-gray-800 dark:text-gray-400" />
              <h1 className="text-xl sm:text-2xl font-bold text-gray-900 dark:text-white">
                Role Permissions
              </h1>
            </div>
            <div>
              <Button
                className="rounded-md bg-[#006AC1] hover:bg-[#006AC1] text-white dark:bg-blue-600 dark:hover:bg-blue-700"
                onClick={() => setIsInviteModalOpen(true)}
              >
                + Invite Agents
              </Button>
            </div>
          </div>
          <div className="relative overflow-x-auto mt-6" ref={tableRef}>
            {renderContent()}
          </div>
        </Card>
      </div>

      {openDropdown && (
        <div 
          ref={dropdownRef}
          className="fixed z-50"
          style={{ top: `${dropdownPosition.top}px`, left: `${dropdownPosition.left}px` }}
        >
          <div className="mt-2 w-36 sm:w-48 bg-white dark:bg-gray-700 rounded-md shadow-lg py-1">
            <button
              onClick={(e) => handleDelete(openDropdown, e)}
              className="flex items-center px-4 py-2 text-xs sm:text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600 w-full text-left"
            >
              <HiTrash className="mr-2 h-4 w-4" />
              Delete
            </button>
          </div>
        </div>
      )}

      <div className="fixed inset-0 bg-black bg-opacity-70 dark:bg-opacity-80 flex items-center justify-center z-50 px-4" style={{ display: isInviteModalOpen ? 'flex' : 'none' }}>
        <div ref={modalRef} className="bg-white dark:bg-gray-800 rounded-lg w-full max-w-lg relative">
          <button
            className="absolute top-3 right-3 text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300"
            onClick={() => setIsInviteModalOpen(false)}
          >
            <HiX className="w-6 h-6" />
          </button>
          <h2 className="text-lg sm:text-xl font-bold mb-4 border-b-2 border-gray-300 dark:border-gray-700 p-4 sm:p-5 text-gray-900 dark:text-white">Invite people to Rupeni AI</h2>
          <div className="space-y-4 px-4 sm:px-5 border-b-2 border-gray-300 dark:border-gray-700 pb-5">
            <div>
              <label htmlFor="inviteEmails" className="block mb-1 text-sm font-medium text-gray-700 dark:text-gray-300">
                <strong>Email Addresses</strong> (up to 10, separated by commas)
              </label>
              <textarea
                id="inviteEmails"
                className="w-full px-3 py-2 text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-700 border border-gray-400 dark:border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-600 text-sm"
                rows={4}
                placeholder="email1@example.com, email2@example.com, ..."
                value={inviteEmails}
                onChange={(e) => setInviteEmails(e.target.value)}
              ></textarea>
            </div>
            <div ref={roleDropdownRef} className="relative">
              <button
                id="inviteRole"
                className="w-full px-4 py-2 text-left bg-white dark:bg-gray-700 border border-gray-400 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-600 text-sm text-gray-700 dark:text-gray-300"
                onClick={() => setIsRoleDropdownOpen(!isRoleDropdownOpen)}
              >
                {inviteRole}
                <HiChevronDown className="float-right mt-1" />
              </button>
              {isRoleDropdownOpen && (
                <div className="absolute z-10 w-full mt-1 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-lg">
                  {["Admin (Billing Access)", "Agent", "Viewer"].map((role) => (
                    <button
                      key={role}
                      className="block w-full px-4 py-2 text-left hover:bg-gray-100 dark:hover:bg-gray-600 focus:outline-none text-sm text-gray-700 dark:text-gray-300"
                      onClick={() => {
                        setInviteRole(role);
                        setIsRoleDropdownOpen(false);
                      }}
                    >
                      {role}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col justify-center space-y-4 p-4 sm:p-5">
            {error && (
              <div className="text-red-500 text-sm">{error}</div>
            )}
            <button
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-600 text-sm disabled:opacity-50"
              onClick={handleInvite}
              disabled={isLoading}
            >
              {isLoading ? "Sending..." : "Send Invites"}
            </button>
          </div>
        </div>
      </div>

      {isDeleteModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-70 dark:bg-opacity-80 flex items-center justify-center z-50 px-4">
          <div className="bg-white dark:bg-gray-800 rounded-lg p-6 max-w-sm w-full">
            <h3 className="text-lg font-bold mb-4 text-gray-900 dark:text-white">
              Confirm Delete
            </h3>
            <p className="text-gray-700 dark:text-gray-300 mb-6">
              Are you sure you want to delete this user? This action cannot be undone.
            </p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => {
                  setIsDeleteModalOpen(false);
                  setUserToDelete(null);
                }}
                className="px-4 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-md"
              >
                Cancel
              </button>
              <button
                onClick={confirmDelete}
                className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{ zIndex: 9999999999999 }}
      />
    </MainLayout>
  );
};

export default RolePermissions;
