import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Label, Spinner } from "flowbite-react";
import { ReactTyped } from "react-typed";
import { rupeni } from "../../assets/images";
import { z } from "zod";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { registerNewUser } from "../../api/authentication"; // Import the function

const userSetupSchema = z.object({
  name: z.string().min(2, "Name must be at least 2 characters long"),
  password: z.string().min(8, "Password must be at least 8 characters long"),
  confirmPassword: z.string()
}).refine((data) => data.password === data.confirmPassword, {
  message: "Passwords do not match",
  path: ["confirmPassword"],
});

const UserSetup: React.FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get("email");
  const companyId = searchParams.get("company_id");

  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    if (!email || !companyId) {
      toast.error("Email or Company ID is missing from the URL.");
      setLoading(false);
      return;
    }

    try {
      const validatedData = userSetupSchema.parse({ name, password, confirmPassword });

      // Call the registerNewUser function with the extracted email and company_id
      const response = await registerNewUser({
        name: validatedData.name,
        password: validatedData.password,
        email,
        company_id: parseInt(companyId, 10),
        title: "User", // You can adjust the title as needed
      });

      toast.success(response);

      // Reset form fields
      setName("");
      setPassword("");
      setConfirmPassword("");

      // Simulate redirect after a short delay
      setTimeout(() => {
        console.log("Redirecting to /authentication");
        // Uncomment the next line when you're ready to implement the actual redirect
         window.location.href = "/authentication?tab=login";
      }, 3000);
    } catch (error) {
      if (error instanceof z.ZodError) {
        error.errors.forEach((err) => {
          toast.error(err.message);
        });
      } else {
        toast.error("An error occurred. Please try again later.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-gray-100 to-gray-300 flex justify-center items-center">
      <div className="flex flex-col lg:flex-row bg-white rounded-lg shadow-lg overflow-hidden max-w-4xl w-full">
        <div className="hidden lg:flex lg:w-1/2 bg-gradient-to-r from-[#0D151F] to-[#1E212A] text-white flex-col items-center justify-center p-8">
          <img
            src={rupeni}
            alt="Logo"
            className="mt-8 w-44 h-44 rounded-full border-4 border-white"
          />
          <div className="flex flex-col items-center justify-center mt-8">
            <h1 className="text-4xl font-bold mb-4">
              <ReactTyped
                strings={["Welcome to Rupeni", "AI Made easy", "Discover the potential of AI with Rupeni"]}
                typeSpeed={100}
                backSpeed={50}
                loop
              />
            </h1>
            <p className="text-lg text-center">
              You're almost there! Set up your account to start using Rupeni.
            </p>
          </div>
        </div>
        <div className="w-full lg:w-1/2 p-8 flex items-center justify-center">
          <div className="bg-white p-10 rounded-lg shadow-lg w-full max-w-lg relative z-10 animate-fade-in">
            <h2 className="text-3xl font-bold text-center mb-8 text-gray-800 animate-slide-in">
              Set Up Your Account
            </h2>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <Label
                  htmlFor="name"
                  value="Name"
                  className="block text-sm font-medium text-gray-700"
                />
                <input
                  id="name"
                  type="text"
                  placeholder="Enter your name"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  aria-required="true"
                />
              </div>
              <div>
                <Label
                  htmlFor="password"
                  value="Password"
                  className="block text-sm font-medium text-gray-700"
                />
                <input
                  id="password"
                  type="password"
                  placeholder="Enter your password"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  aria-required="true"
                />
              </div>
              <div>
                <Label
                  htmlFor="confirm-password"
                  value="Confirm Password"
                  className="block text-sm font-medium text-gray-700"
                />
                <input
                  id="confirm-password"
                  type="password"
                  placeholder="Confirm your password"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  aria-required="true"
                />
              </div>
              <div className="flex justify-center">
                <button
                  className="w-56 bg-bg border border-bg hover:border-primary text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-bg transition duration-300 transform hover:scale-105"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? (
                    <Spinner aria-label="Loading" size="sm" />
                  ) : (
                    <span className="text-sm p-8">Set Up Account</span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" autoClose={3000} />
    </div>
  );
};

export default UserSetup;
