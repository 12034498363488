import {  Spinner } from "flowbite-react";
import React, { useState } from "react";
import { resetPassword } from "../../api/authentication";

interface ForgotPasswordComponentProps {
  setTab: (tab: string) => void;
}

const ForgotPasswordComponent: React.FC<ForgotPasswordComponentProps> = ({ setTab }) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState<string | null>(null);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [loading, setLoading] = useState(false);

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async () => {
    const newErrors: { [key: string]: string } = {};

    if (!validateEmail(email)) {
      newErrors.email = "Invalid email format";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setLoading(true);
      const response = await resetPassword(email);
      if (response === "Password reset email sent!") {
        setEmail("");
        setErrors({});
        setMessage(response);
      } else {
        setErrors({
          apiError: response || "An error occurred. Please try again later.",
        });
      }
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center lg:col-span-3 p-4">
      <div className="bg-white p-10 rounded-lg shadow-lg w-full max-w-lg relative z-10 animate-fade-in">
        <h2 className="text-3xl font-bold text-center mb-8 text-gray-800 animate-slide-in">
          Reset Your Password
        </h2>
        {message ? (
          <div className="flex flex-col items-center justify-center text-center">
            <p className="text-green-500 mb-4" aria-live="polite">
              {message}
            </p>
            <button
             className="w-48 bg-bg border border-bg hover:border-primary text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-bg transition duration-300 transform hover:scale-105"
              onClick={() => setTab("login")}
            >
               <span className="text-sm">Login Now</span>
            </button>
          </div>
        ) : (

            <div>
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white animate-slide-in delay-200"
              >
                Email
              </label>
              <div className="relative mb-8 animate-slide-in delay-200">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none text-gray-400">
                  <i className="fas fa-envelope"></i>
                </div>
                <input
                  type="email"
                  id="email"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onBlur={() => {
                    if (!validateEmail(email)) {
                      setErrors((prev) => ({
                        ...prev,
                        email: "Invalid email format",
                      }));
                    } else {
                      setErrors((prev) => {
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        const { email, ...rest } = prev;
                        return rest;
                      });
                    }
                  }}
                  required
                  disabled={loading}
                />
                {errors.email && (
                  <p className="text-red-500 text-xs mt-1" aria-live="polite">
                    {errors.email}
                  </p>
                )}
              </div>

              <div className="flex flex-col items-center justify-center animate-slide-in delay-300 mb-8">
                <button
                  className="w-48 bg-bg border border-bg hover:border-primary text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-bg transition duration-300 transform hover:scale-105"
                  type="button"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading ? (
                      <Spinner aria-label="Spinner" size="sm" />
                  ) : (
                    <span className="text-sm">Reset Password</span>
                  )}
                </button>
                {errors.apiError && (
                  <p className="text-red-500 text-xs mt-1" aria-live="polite">
                    {errors.apiError}
                  </p>
                )}
              </div>
            </div>

        )}

        <div className="text-center mt-8 animate-slide-in delay-700">
          <p className="text-sm text-gray-500">
            Remembered your password?{" "}
            <button
              onClick={() => setTab("login")}
              className="text-blue-500 hover:text-blue-700 cursor-pointer"
            >
              Login
            </button>
          </p>
          <p className="text-sm text-gray-500">
            Don't have an account?{" "}
            <button
              onClick={() => setTab("registration")}
              className="text-blue-500 hover:text-blue-700 cursor-pointer"
            >
              Sign Up
            </button>
          </p>
        </div>
        <div className="absolute inset-0 bg-gradient-to-r from-purple-300 to-transparent opacity-10 pointer-events-none"></div>
      </div>
    </div>
  );
};

export default ForgotPasswordComponent;
